import * as React from "react";
import UnstyledButton from "../UnstyledButton/UnstyledButton";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { Text } from "src/components/Text";
import "./CalypsoButton.scss";

interface IProps {
    title: string;
    text?: string;
    icon?: string;
    onClick?: any;
    theme?: "light" | "primary";
    classNames?: string[];
}

const CalypsoButton = (props: IProps) => {
    const isColored = props.theme === "primary";

    return (
        <UnstyledButton
            className={
                [
                    ...(props.classNames || []),
                    "CalypsoButton",
                    !props.text ? "icon-only" : "",
                    isColored ? "primaryBGColor" : ""
                ].join(" ")
            }
            title={props.title}
            onClick={props.onClick}>
            {props.icon && <Visage2Icon icon={props.icon} color={isColored ? "white" : "dark-grey"} size={props.icon === "add" ? "medium" : "small"} />}
            {props.text && <Text size="caption" color={isColored ? "white" : "grayDark"}>{props.text}</Text>}
        </UnstyledButton>
    )
}

export default CalypsoButton;
