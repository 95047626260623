import { AxiosResponse } from 'axios';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { localize } from 'src/l10n';
import { setConfirmPopup } from 'src/popups/actions';
import api from 'src/spintr/SpintrApi';
import { ActionMenu, ContentWithSubmenu, PageHeader } from 'src/ui';
import SpintrList from 'src/ui/components/SpintrList/SpintrList';
import SuppliersBreadcrumbs from './SuppliersBreadcrumbs';
import { updateSidebar } from './utils';

interface IProps {
    instance?: any;
    currentUser?: any;
    history?: any;
    isAdmin?: boolean;
    isEditor?: boolean;
    dispatch?: any;
    appName?: string;
}

interface IState {
    isLoading: boolean;
    roles: any[];
    displayDeleted: boolean;
}

class SuppliersRolesView extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrList>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
            roles: [],
            displayDeleted: false
        };
    }

    componentDidMount() {
        updateSidebar(this.props.isAdmin, this.props.isEditor, this.props.appName, this.props.dispatch);
    }

    saveOrder(order: number[]) {
        api.post("/api/suppliers/roles/order", order).then((response: AxiosResponse) => {
            this.listRef.current.reFetch();
        }).catch(() => {});
    }

    moveUp(id: number) {
        let order = this.state.roles.map((item: any) => {
            return item.id;
        });

        const fromIndex = order.indexOf(id);

        if (fromIndex < 1) {
            return;
        }

        const toIndex = fromIndex - 1;

        order.splice(fromIndex, 1);
        order.splice(toIndex, 0, id);

        this.saveOrder(order);
    }

    moveDown(id: number) {
        let order = this.state.roles.map((item: any) => {
            return item.id;
        });

        const fromIndex = order.indexOf(id);

        if (fromIndex === (order.length - 1)) {
            return;
        }

        const toIndex = fromIndex + 1;

        order.splice(fromIndex, 1);
        order.splice(toIndex, 0, id);

        this.saveOrder(order);
    }

    render() {
        return (
            <div className="SuppliersView">
                <div className="breadcrumbs-wrapper">
                    <SuppliersBreadcrumbs />
                </div>
                <Helmet>
                    <title>{localize("Roller")}</title>
                </Helmet>
                <PageHeader
                    title={localize("Roller")}
                    displaySearch
                    onSearchQueryChange={(value: string) => {
                        this.listRef.current.setSearchText(value);
                    }}
                    buttons={!this.props.isAdmin && !this.props.isEditor ? [] : [
                        {
                            key: "add",
                            text: localize("SkapaNy"),
                            onClick: () => {
                                this.props.history.push({
                                    pathname: `/suppliers/roles/create`,
                                });
                            },
                            iconProps: { iconName: "Add" },
                            className: "commandBarAddButton",
                            theme: "primary"
                        },
                    ]}
                />
                <SpintrList
                    ref={this.listRef}
                    fetch={(skip, take, columnId, isAscending, searchQuery) => {
                        return new Promise((resolve, reject) => {
                            api.get("/api/suppliers/roles", {
                                params: {
                                    searchText: searchQuery,
                                },
                            }).then((response: AxiosResponse) => {
                                const roles = response.data.sort((a, b) => {
                                    if (a.order < b.order) { return -1; }
                                    if (a.order > b.order) { return 1; }
                                    return 0;
                                }).map((r: any, index: number) => {
                                    return {
                                        ...r,
                                        isFirst: index === 0,
                                        isLast: index === (response.data.length - 1)
                                    }
                                });

                                this.setState({
                                    roles
                                });

                                resolve({ data: roles, totalCount: roles.length });
                            })
                        });
                    }}
                    take={9999}
                    disableSort
                    orderByColumn={"Name"}
                    columns={[
                        {
                            name: localize("Namn"),
                            fieldName: "name",
                            minWidth: 250,
                            onRender: (item) => {
                                return (
                                    <span
                                        style={{
                                            ...(item.isDeleted ? { textDecorationLine: "line-through" } : {}),
                                        }}
                                    >
                                        {item.name}
                                    </span>
                                );
                            },
                        },
                        {
                            name: "",
                            key: "actionmenu",
                            minWidth: 40,
                            onRender: (item: any) => {
                                if (!this.props.isAdmin && !this.props.isEditor) {
                                    return null;
                                }

                                let items = [];

                                if (!item.isFirst) {
                                    items.push({
                                        text: localize("FlyttaUpp"),
                                        onClick: () => {
                                            this.moveUp(item.id);
                                        },
                                    });
                                }

                                if (!item.isLast) {
                                    items.push({
                                        text: localize("FlyttaNer"),
                                        onClick: () => {
                                            this.moveDown(item.id);
                                        },
                                    });
                                }

                                items.push({
                                    text: localize("Redigera"),
                                    onClick: () => {
                                        this.props.history.push({
                                            pathname: `/suppliers/roles/${item.id}/edit`,
                                        });
                                    },
                                });

                                items.push({
                                    text: localize("TaBort"),
                                    onClick: () => {
                                        const onConfirm = () => {
                                            api.delete(`/api/suppliers/roles/${item.id}`).then(() => {
                                                this.listRef.current.fetch();
                                            });
                                        }

                                        const title = item.isDeleted ?
                                            localize("Aterstallinnehall") :
                                            localize("RaderaInnehall");

                                        const message = item.isDeleted ?
                                            localize("ArDuSakerPaAttDuVillGoraDetta") :
                                            localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("RaderaDetta").toLowerCase());

                                        this.props.dispatch(setConfirmPopup({
                                            isOpen: true,
                                            title,
                                            message,
                                            onConfirm
                                        }));
                                    },
                                });

                                return (
                                    <ActionMenu
                                        categories={[
                                            {
                                                items
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ]}
                    disableCommandBar
                    disableSearch
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,
    instance: state.instance,
    currentUser: state.profile.active,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    appName: state.instance.get("suppliersAppName")
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(SuppliersRolesView));
