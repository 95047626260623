import React, { ReactElement, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { localize } from "src/l10n";
import { TimelineProps, TimelineState, TimelineStateProps } from "./Timeline.types";
import { TimelineHeader } from "../TimelineHeader";
import { TimelineCategories } from "../TimelineCategories";
import { ColumnDuration, DisplayedItemsChangeHandler, PlannerTimelineCategory, PlannerTimelineItem } from "../types";
import { generateColumnDurations, getTimelineDuration } from "../utils";
import { TimelineOverlay } from "../TimelineOverlay";
import { useDispatch, useSelector } from "react-redux";
import { plannerItemUpdated, queryPlannerItems } from "src/redux";
import { SpintrTypes } from "src/typings";
import { updatePlannerItemAsync } from "src/api/plannerApi";

const axisWidth = 257;
const dayCellWidth = 96;
const regularCellWidth = 255;

function Timeline(props: TimelineProps): ReactElement {
    const [state, _] = useState<TimelineState>(() => {
        const now = new Date();
        now.setHours(12, 0, 0, 0);

        return { todayMs: now.getTime() }
    });

    const dispatch = useDispatch();
    const stateProps = useSelector<Spintr.AppState, TimelineStateProps>(
        (appState) => ({
            itemLookup: appState.planner.itemsById,
            queryItemIds: appState.planner.queryItemIds,
            user: appState.profile.active,
        }),
        (left, right) => {
            if (left.user.id !== right.user.id) {
                return false;
            }

            if (left.queryItemIds !== right.queryItemIds) {
                return false;
            }

            if (left.itemLookup !== right.itemLookup) {
                return false;
            }

            return true;
        }
    );

    const [data, setData] = useState<PlannerTimelineCategory[]>([]);

    useEffect(() => {
        const { user, itemLookup } = stateProps;

        const searchText = (props.searchText || "").toLowerCase();

        var items = Object.values(itemLookup)
            .filter((item) => searchText.length === 0 || item.name.toLowerCase().includes(searchText))
            .sort((a, b) => a.startsAt.getTime() - b.startsAt.getTime());

        function mapToTimelineItem(item: Spintr.PlannerItem): PlannerTimelineItem {
            return {
                key: item.id,
                name: item.name,
                start: item.startsAt,
                end: item.endsAt,
                color: item.color,
            };
        }

        const categories: PlannerTimelineCategory[] = [];

        const myItems = items.filter((item) => item.plannerOwner?.id == user.id);
        if (myItems.length > 0) {
            const previous = data.find((category) => category.key === "you");

            categories.push({
                key: "you",
                name: localize("PLANNER_CATEGORY_YOURS"),
                expanded: previous?.expanded ?? true,
                itemsDisplayed: searchText.length === 0
                    ? (previous && previous.itemsDisplayed > 3 ? myItems.length : Math.min(3, myItems.length))
                    : myItems.length,
                items: myItems.map(mapToTimelineItem),
            });
        }

        const regionalIds: number[] = [
            user.department.id,
            user.department.office?.id,
            user.department.office?.company?.id
        ].filter((id) => !!id);

        const regionalItems = items.filter((item) => regionalIds.includes(item.plannerOwner?.id));
        if (regionalItems.length > 0) {
            const previous = data.find((category) => category.key === "regional");

            categories.push({
                key: "regional",
                name: localize("PLANNER_CATEGORY_REGIONAL"),
                expanded: previous?.expanded ?? true,
                itemsDisplayed: searchText.length === 0
                    ? (previous && previous.itemsDisplayed > 3 ? regionalItems.length : Math.min(3, regionalItems.length))
                    : regionalItems.length,
                items: regionalItems.map(mapToTimelineItem),
            });
        }

        const groupItems = items.filter((item) => item.plannerOwner?.type === SpintrTypes.UberType.Group);
        if (groupItems.length > 0) {
            const previous = data.find((category) => category.key === "group");

            categories.push({
                key: "group",
                name: localize("PLANNER_CATEGORY_GROUPS"),
                expanded: previous?.expanded ?? true,
                itemsDisplayed: searchText.length === 0
                    ? (previous && previous.itemsDisplayed > 3 ? groupItems.length : Math.min(3, groupItems.length))
                    : groupItems.length,
                items: groupItems.map(mapToTimelineItem),
            });
        }

        const globalItems = items.filter((item) => !item.plannerOwner || item.plannerOwner.id === 0);
        if (globalItems.length > 0) {
            const previous = data.find((category) => category.key === "global");

            categories.push({
                key: "global",
                name: localize("PLANNER_CATEGORY_GLOBAL"),
                expanded: previous?.expanded ?? true,
                itemsDisplayed: searchText.length === 0
                    ? (previous && previous.itemsDisplayed > 3 ? globalItems.length : Math.min(3, globalItems.length))
                    : globalItems.length,
                items: globalItems.map(mapToTimelineItem),
            });
        }

        setData(categories);
    }, [props.searchText, stateProps.itemLookup, stateProps.user, setData]);

    const onCategoryClick = useCallback(
        (category: PlannerTimelineCategory) => setData(
            (previousData) => previousData.map((c) => c.key === category.key
                ? { ...c, expanded: !c.expanded }
                : c,
            ),
        ),
        [setData],
    );

    const onItemDurationChange = useCallback(
        async (item: PlannerTimelineItem, startTime: number, endTime: number) => {
            const id = typeof item.key === "number" ? item.key : parseInt(item.key, 10);
            const plannerItem = stateProps.itemLookup[id];
            if (!plannerItem) {
                return;
            }

            const prevStart = plannerItem.startsAt;
            const prevEnd = plannerItem.endsAt;

            dispatch(plannerItemUpdated({
                ...plannerItem,
                startsAt: new Date(startTime),
                endsAt: new Date(endTime),
            }));

            try {
                await updatePlannerItemAsync(plannerItem.id, {
                    ...plannerItem,
                    approvedBy: plannerItem.approvedBy?.id,
                    assignees: (plannerItem.assignees || []).map(
                        (item) => item.id,
                    ),
                    targetedTo: (plannerItem.targetedTo || []).map(
                        (item) => item.id,
                    ),
                    files: undefined,
                    plannerOwner: plannerItem.plannerOwner?.id,
                    participants: plannerItem.itemType === SpintrTypes.PlannerItemType.Event
                        ? (plannerItem.participants || []).map((p) => p.user?.id)
                        : undefined,
                });
            } catch (_) {
                dispatch(plannerItemUpdated({
                    ...plannerItem,
                    startsAt: prevStart,
                    endsAt: prevEnd,
                }));
            }
        },
        [dispatch, stateProps.itemLookup],
    );

    const { todayMs } = state;
    const {
        onBarClick,
        timelineMode,
    } = props;

    const timelineRef = useRef<HTMLDivElement>(null);

    const columnDurations = useMemo<ColumnDuration[]>(
        () => generateColumnDurations(new Date(), timelineMode),
        [timelineMode]
    );

    const timelineDuration: ColumnDuration = useMemo(
        () => getTimelineDuration(columnDurations),
        [columnDurations]
    );

    const timelineWidth = useMemo<number>(
        () => (timelineMode === "DAYS" ? dayCellWidth : regularCellWidth) * columnDurations.length,
        [columnDurations.length, timelineMode]
    );

    const onDisplayedItemsChanged = useCallback<DisplayedItemsChangeHandler>(
        (category, count) => setData(
            (categories) => categories.map(
                (c) => c.key === category.key
                    ? { ...c, itemsDisplayed: count }
                    : c
            ),
        ),
        [setData],
    );

    const scrollToDate = useCallback((date: Date) => {
        if (!timelineRef.current) {
            return;
        }

        const time = date.getTime();
        const { startMilliseconds, totalMilliseconds } = timelineDuration;

        if (time < startMilliseconds || time > startMilliseconds + totalMilliseconds) {
            return;
        }

        const position = ((time - startMilliseconds) / totalMilliseconds) * timelineRef.current!.scrollWidth;

        timelineRef.current.scrollTo({ left: position - axisWidth - 295, behavior: 'smooth' })
    }, [timelineDuration, timelineRef, axisWidth]);

    useEffect(() => { // Scroll to current date when the view has loaded
        const timeoutRef = setTimeout(() => scrollToDate(new Date()), 100);

        return () => clearTimeout(timeoutRef);
    }, [timelineRef.current, timelineMode]);

    useEffect(() => {
        dispatch(queryPlannerItems({
            after: new Date(timelineDuration.startMilliseconds),
            before: new Date(timelineDuration.endMilliseconds),
            status: SpintrTypes.ContentStatus.Published,
        }));
    }, [dispatch]);

    const columnWidth = timelineMode === "DAYS" ? dayCellWidth : regularCellWidth;

    return (
        <section className="Timeline">
            <div className="Timeline-roadmap-wrapper">
                <div
                    aria-label={localize("PLANNER_TIMELINE")}
                    className="Timeline-roadmap"
                    role="grid"
                >
                    <div className="timeline-wrapper">
                        <div className="timeline" ref={timelineRef}>
                            <div className="timeline-rows-wrapper">
                                <TimelineHeader
                                    columnDurations={columnDurations}
                                    columnWidth={columnWidth}
                                    timelineDuration={timelineDuration}
                                    timelineMode={timelineMode}
                                    timelineSize={timelineWidth}
                                    todayMs={todayMs} />

                                <TimelineCategories
                                    axisWidth={axisWidth}
                                    categories={data}
                                    columnDurations={columnDurations}
                                    onDisplayedItemsChange={onDisplayedItemsChanged}
                                    onBarClick={onBarClick}
                                    onCategoryClick={onCategoryClick}
                                    onItemDurationChange={onItemDurationChange}
                                    timelineDuration={timelineDuration}
                                    timelineSize={timelineWidth}
                                    todayTime={todayMs} />

                                <TimelineOverlay
                                    axisWidth={axisWidth}
                                    columnDurations={columnDurations}
                                    columnWidth={columnWidth}
                                    timelineDuration={timelineDuration}
                                    timelineMode={timelineMode}
                                    timelineWidth={timelineWidth}
                                    todayMs={todayMs} />

                                <div className="Timeline-axisBorder-wrapper">
                                    <div
                                        className="Timeline-axisBorder"
                                        style={{ width: `${axisWidth}px` }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Timeline;
