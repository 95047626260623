import React, { CSSProperties, ReactElement, useMemo } from "react";
import { TimelineOverlayProps } from "./TimelineOverlay.types";
import { TimelineCellOverlay } from "../TimelineCellOverlay";

function TimelineOverlay(props: TimelineOverlayProps): ReactElement {
    const { axisWidth, columnDurations, columnWidth, timelineMode, timelineDuration, timelineWidth, todayMs } = props;

    const chartStyle = useMemo<CSSProperties>(() => ({
        width: `${timelineWidth}px`,
    }), [timelineWidth]);

    const todayStyle = useMemo<CSSProperties>(() => {
        const relativeToday = todayMs - timelineDuration.startMilliseconds;
        const percentage = relativeToday / timelineDuration.totalMilliseconds;

        return {
            left: `${timelineWidth * percentage}px`,
        };
    }, [axisWidth, timelineDuration, timelineWidth, todayMs]);

    const todayIndex = useMemo(() => columnDurations.findIndex(
        columnDuration => columnDuration.startMilliseconds <= todayMs && todayMs <= columnDuration.endMilliseconds
    ), [columnDurations, todayMs]);

    return (
        <div className="TimelineOverlay">
            <div className="TimelineOverlay-axis" style={{ width: `${axisWidth}px` }} />

            <div className="TimelineOverlay-chart" style={chartStyle}>
                <div className="TimelineOverlay-gridLines">
                    {columnDurations.map((_, index) => (
                        <TimelineCellOverlay
                            columnWidth={columnWidth}
                            index={index}
                            intervalCount={columnDurations.length}
                            isToday={todayIndex === index}
                            key={index}
                            timelineMode={timelineMode}
                            timelineWidth={timelineWidth} />
                    ))}
                </div>

                <div className="TimelineOverlay-today" style={todayStyle}>
                    <div className="TimelineOverlay-todayLine">
                        <span className="TimelineOverlay-todayArrow" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TimelineOverlay;
