import React, { Fragment, ReactElement, useCallback, useEffect } from "react";
import { PlannerItemDetailDrawerProps } from "./PlannerItemDetailDrawer.types";
import { Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle } from "src/components/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { Conditional } from "src/components/Conditional";
import SpintrLoader from "src/ui/components/Loader";
import { getCancelTokenSource } from "src/api";
import { getPlannerItemAsync } from "src/api/plannerApi";
import { plannerItemCreated } from "src/redux";
import { UnstyledButton } from "src/ui";
import { localize } from "src/l10n";
import { Icon } from "@fluentui/react";
import { PlannerItemDetail } from "../PlannerItemDetail";

function PlannerItemDetailDrawer(props: PlannerItemDetailDrawerProps): ReactElement {
    const { open, onEditClick, onDismiss, selectedId } = props;
    const dispatch = useDispatch();

    const item = useSelector<Spintr.AppState, Spintr.PlannerItem | undefined>(
        (appState) => !selectedId ? undefined : appState.planner.itemsById[selectedId],
    );

    const onOpenChanged = useCallback(
        (open) => {
            if (open) {
                return;
            }
            
            onDismiss?.();
        },
        [onDismiss],
    );

    const onEditClicked = useCallback(() => {
        if (!selectedId) {
            return;
        }

        onEditClick?.(selectedId);
    }, [onEditClick, selectedId]);

    useEffect(() => {
        if (!selectedId || item) {
            // Maybe onDismiss should be called here
            console.log(selectedId, item);
            return;
        }

        const cancelTokenSource = getCancelTokenSource();
        getPlannerItemAsync(selectedId, cancelTokenSource.token)
            .then((item) => dispatch(plannerItemCreated(item)))
            .catch((error) => {
                console.error(error);

                onDismiss?.();
            });

        return () => cancelTokenSource.cancel();
    }, [selectedId, item, onDismiss]);

    const headerActons = (
        <div className="actions">
            <UnstyledButton
                className="edit-button"
                title={localize("Redigera")}
                onClick={onEditClicked}
            >
                <Icon iconName="Edit" />
            </UnstyledButton>
            <UnstyledButton
                className="close-button"
                title={localize("Stang")}
                onClick={onDismiss}
            >
                <Icon iconName="ChromeClose" />
            </UnstyledButton>
        </div>
    );

    return (
        <Drawer
            className="PlannerItemDetailDrawer"
            onOpenChange={onOpenChanged}
            open={open}
            position="end"
            size="medium"
        >
            <Conditional condition={item !== undefined}>
                <DrawerHeader>
                    <DrawerHeaderTitle
                        action={headerActons}
                        className="PlannerItemDetailDrawer-header"
                    >
                        {item?.name}
                    </DrawerHeaderTitle>
                </DrawerHeader>
            </Conditional>

            <DrawerBody>
                <Conditional condition={item === undefined}>
                    <SpintrLoader />
                </Conditional>

                <Conditional condition={item !== undefined}>
                    <PlannerItemDetail item={item} />
                </Conditional>
            </DrawerBody>
        </Drawer>
    )
}

export default PlannerItemDetailDrawer;
