import React, { ReactElement, useMemo } from "react";
import { DrawerHeaderTitleProps } from "./DrawerHeaderTitle.types";
import { LargeBody } from "src/components/Text";
import classNames from "classnames";

function DrawerHeaderTitle(props: DrawerHeaderTitleProps): ReactElement {
    const action = props.action ? (
        <div className="DrawerHeaderTitle-action">
            {props.action}
        </div>
    ) : null;

    const className = useMemo(() => classNames("DrawerHeaderTitle", props.className), [props.className]);

    return (
        <div className={className}>
            <LargeBody className="DrawerHeaderTitle-text" as="h2" color="contentDark" size="bodyMedium">
                {props.children}
            </LargeBody>
            {action}
        </div>
    );
}

export default DrawerHeaderTitle;
