import { Icon, PrimaryButton } from "@fluentui/react";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle } from "src/components/Drawer";
import { localize } from "src/l10n";
import { UnstyledButton } from "src/ui";
import { PlannerItemForm } from "../PlannerItemForm";
import { PlannerItemFormDrawerProps, PlannerItemFormDrawerState, PlannerItemFormDrawerStateProps } from "./PlannerItemFormDrawer.types";
import { PlannerModel, PlannerModelUpdater } from "../types";
import { SpintrTypes } from "src/typings";
import { SmallBody } from "src/components/Text";
import { createPlannerItemAsync, updatePlannerItemAsync, uploadPlannerFileAsync } from "src/api/plannerApi";
import { Conditional } from "src/components/Conditional";
import SpintrLoader from "src/ui/components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmPopup } from "src/popups/actions";

const barColors: string[] = [
    "#FF6AA9",
    "#FFE664",
    "#FF8064",
    "#4ECAFF",
    "#17B26A",
    "#7F56D9",
    "#C9CBF1",
];

function createEmptyPlannerItem(stateProps: PlannerItemFormDrawerStateProps): PlannerModel {
    const now = new Date();

    return {
        plannerOwner: stateProps.hasElevatedPermissions ? undefined : {
            id: stateProps.userId,
            name: "",
            imageUrl: "",
            type: 1,
        },
        name: "",
        color: barColors[0],
        assignees: [],
        targetedTo: [],
        description: "",
        itemType: SpintrTypes.PlannerItemType.General,
        startsAt: now,
        endsAt: new Date(now.getTime() + (24 * 60 * 60 * 1000)),
        allDayEvent: true,
        location: "",
        requiredResources: [],
        tags: [],
        files: [],
        removedFiles: [],
    };
}

function PlannerItemFormDrawer(props: PlannerItemFormDrawerProps): ReactElement {
    const { onDismiss, open } = props;

    const dispatch = useDispatch();

    const stateProps = useSelector<Spintr.AppState, PlannerItemFormDrawerStateProps>(
        (appState) => ({
            hasElevatedPermissions: appState.profile.active.roles.some(
                (role) => role === "administrators" || role === "editor",
            ),
            userId: appState.profile.active.id,
        }),
        (left, right) =>
            left.hasElevatedPermissions === right.hasElevatedPermissions &&
            left.userId === right.userId,
    );

    const selectedItem = useSelector<Spintr.AppState, Spintr.PlannerItem | undefined>(
        (appState) => !props.itemId ? undefined : appState.planner.itemsById[props.itemId],
    );

    const [state, setState] = useState<PlannerItemFormDrawerState>({
        errors: {},
        isNew: true,
        isSaving: false,
    });

    const [model, setModel] = useState<PlannerModel>(createEmptyPlannerItem(stateProps));

    const updateModel = useCallback(
        (updater: PlannerModelUpdater) => {
            setModel(updater);
            setState((prevState) => ({ ...prevState, dirty: true }));
        },
        [setModel, setState],
    );

    const onOpenChanged = useCallback(
        (shouldBeOpen: boolean) => {
            if (shouldBeOpen) {
                return;
            }

            if (!state.dirty) {
                onDismiss?.(false);
                return;
            }

            dispatch(setConfirmPopup({
                isOpen: true,
                message: localize("UnsavedChangesWarning"),
                onConfirm: () => {
                    setModel(createEmptyPlannerItem(stateProps));
                    setState((prevState) => ({ ...prevState, dirty: false }));
                    onDismiss?.(false);
                },
            }));
        },
        [onDismiss, setModel, stateProps, state.dirty],
    );

    const onDismissed = useCallback((created?: boolean | undefined) => {
        setModel(createEmptyPlannerItem(stateProps));
        setState((prevState) => ({ ...prevState, dirty: false }));

        if (!onDismiss) {
            return;
        }

        onDismiss(created);
    }, [onDismiss, setModel, stateProps.userId, stateProps.hasElevatedPermissions]);

    const { isSaving } = state;
    const onSubmit = useCallback(async (status: Spintr.ContentStatus) => {
        if (isSaving) {
            return;
        }

        setState((prevState) => ({ ...prevState, isSaving: true }));

        let plannerItem: Spintr.PlannerItem | undefined;
        try {
            const modelToSend = {
                ...model,
                contentStatus: status,
                approvedBy: model.approvedBy?.id,
                assignees: (model.assignees || []).map(
                    (item) => item.id,
                ),
                targetedTo: (model.targetedTo || []).map(
                    (item) => item.id,
                ),
                files: undefined,
                participants: model.itemType !== SpintrTypes.PlannerItemType.Event
                    ? undefined
                    : (model.participants || []).map((p) => p.id),
                plannerOwner: model.plannerOwner?.id,
            };

            plannerItem = await (state.isNew
                ? createPlannerItemAsync(modelToSend)
                : updatePlannerItemAsync(props.itemId!, modelToSend));
        } catch (err) {
            console.error(err);

            setState((prevState) => ({ ...prevState, isSaving: false }));
            return;
        }

        if (!plannerItem) {
            setState((prevState) => ({ ...prevState, isSaving: false }));
            return;
        }

        const files = (model.files || []).filter((file) => file.itemType === plannerItem.itemType);
        for (const file of files) {
            if (!file.localId) {
                continue;
            }

            try {
                const plannerFile = await uploadPlannerFileAsync(plannerItem.id, {
                    file: file.file,
                    fieldType: file.fieldType,
                    itemType: plannerItem.itemType,
                });

                // TODO: dispatch the file to the store
            } catch (err) {
                console.error(err);
    
                setState((prevState) => ({ ...prevState, isSaving: false }));
                onDismissed(true);
            }
        }

        for (const file of model.removedFiles) {
            // TODO: Remove files
        }

        setState((prevState) => ({ ...prevState, isSaving: false }));
        onDismissed(true);
    }, [onDismissed, setState, model, isSaving]);

    const onSaveAsDraftClicked = useCallback(
        () => onSubmit(SpintrTypes.ContentStatus.Draft),
        [onSubmit],
    );

    const onSaveClicked = useCallback(
        () => onSubmit(SpintrTypes.ContentStatus.Published),
        [onSubmit],
    );

    useEffect(() => {
        // TODO: Validate model
    }, [model]);

    useEffect(() => {
        if (!props.itemId) {
            setState((prevState) => ({ ...prevState, isNew: true }));
            return; // Creating new
        }

        if (!selectedItem) {
            // TODO: Fetch item
            return;
        }

        setModel({
            ...createEmptyPlannerItem(stateProps),
            plannerOwner: selectedItem.plannerOwner,
            name: selectedItem.name,
            color: selectedItem.color,
            assignees: selectedItem.assignees ?? [],
            targetedTo: selectedItem.targetedTo ?? [],
            description: selectedItem.description,
            itemType: selectedItem.itemType,
            startsAt: selectedItem.startsAt,
            endsAt: selectedItem.endsAt,
            allDayEvent: selectedItem.allDayEvent,
            location: selectedItem.location,
            requiredResources: selectedItem.requiredResources ?? [],
            tags: selectedItem.tags,
            files: selectedItem.files.map((file) => ({
                fieldType: file.fieldType,
                localId: file.id,
                fileName: file.fileName,
                itemType: file.itemType,
                fileSize: 0,
                fileType: file.fileType,
            })),
            removedFiles: [],
        });
        setState((prevState) => ({ ...prevState, isNew: false }));
    }, [selectedItem, setModel]);

    return (
        <Drawer
            className="PlannerItemFormDrawer"
            onOpenChange={onOpenChanged}
            open={open}
            position="end"
            size="medium"
        >
            <DrawerHeader>
                <DrawerHeaderTitle
                    action={
                        <UnstyledButton
                            className="close-button"
                            title={localize("Stang")}
                            onClick={props.onDismiss}
                        >
                            <Icon iconName="ChromeClose" />
                        </UnstyledButton>
                    }
                >
                    {localize(state.isNew ? "PLANNER_CREATE" : "PLANNER_EDIT")}
                </DrawerHeaderTitle>
            </DrawerHeader>

            <DrawerBody className="PlannerItemFormDrawer-body">

                <Conditional condition={!state.isSaving}>
                    <PlannerItemForm
                        errors={state.errors}
                        model={model}
                        onModelUpdate={updateModel}
                        userId={stateProps.userId} />
                </Conditional>

                <Conditional condition={state.isSaving}>
                    <SpintrLoader />
                </Conditional>

            </DrawerBody>

            <DrawerFooter className="PlannerItemFormDrawer-footer">
                <div className="PlannerItemFormDrawer-cancelAction">
                    <UnstyledButton onClick={props.onDismiss} disabled={state.isSaving}>
                        <SmallBody color="contentLight" weight="medium">
                            {localize("Avbryt")}
                        </SmallBody>
                    </UnstyledButton>
                </div>
                <div className="PlannerItemFormDrawer-saveActions">
                    <PrimaryButton
                        className="PlannerItemFormDrawer-button PlannerItemFormDrawer-draftButton"
                        disabled={state.isSaving}
                        onClick={onSaveAsDraftClicked}
                    >
                        {localize("SparaSomUtkast")}
                    </PrimaryButton>
                    <PrimaryButton
                        className="PlannerItemFormDrawer-button PlannerItemFormDrawer-saveButton"
                        disabled={state.isSaving}
                        onClick={onSaveClicked}
                    >
                        {localize("Spara")}
                    </PrimaryButton>
                </div>
            </DrawerFooter>
        </Drawer>
    );
}

export default PlannerItemFormDrawer;
