import { AxiosResponse } from "axios";
import {
    CommandBar,
    DetailsHeader,
    IColumn,
    IconButton,
    IDetailsHeaderProps,
    IGroup,
    IGroupHeaderProps,
    SearchBox,
    SelectionMode,
    ShimmeredDetailsList,
} from "@fluentui/react";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import { ContentWithSubmenu, Submenu, Breadcrumbs, SpintrUser, ActionMenu, PageHeader, UnstyledButton } from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { Label as SpintrLabel } from "src/ui";
import { debounce } from "src/utils";
import SpintrLoader from "src/ui/components/Loader";
import api from "src/spintr/SpintrApi";
import { IVisageSidebarMenuItem, VisageSidebarMenuItemActiveMode, VisageSidebarMode } from "src/sidebar";
import { expandToActiveItem, setSidebarItemChildren, setSidebarItems, setSidebarMode } from "src/sidebar/actions";
import "./OrganisationListView.scss";
import SpintrSearch from "src/ui/components/SpintrList/SpintrSearch";
import DeactivateDepartmentUsersModal from "../components/DeactivateDepartmentUsersModal";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps extends RouteComponentProps {
    api: any;
    match: any;
    history: any;
    isAdmin: boolean;
    enableCompanyLevel: boolean;
    showUnitsWithoutPagesInOverview: boolean;
    collapsibleUnitsList: boolean;
    dispatch?: any;
}

interface IState {
    isLoading: boolean;
    includeDeleted: boolean;
    showDeleteDialog: boolean;
    goToAll: boolean;
    activeItem: IOrganisationItem;
    unitId: number;
    companyId?: number;
    totalCount: number;
    showUnitsWithoutPagesInSubpage: boolean;
    groups: any;
    items: any;
    searchText: string;
}

interface IOrganisationItem {
    id: number;
    isOffice: boolean;
    departmentsCount: number;
    emailAddress?: string;
    hasPage?: boolean;
    imageUrl?: string;
    isFollowing?: boolean;
    message?: string;
    name?: string;
    phoneNumber?: string;
    userCount?: number;
    userId?: number;
    officeId?: number;
}

interface IDetailsListGroupedLargeExampleItem {
    key: string;
    name: string;
    value: string;
}

class OrganisationListView extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrList>();
    private _groups: IGroup[];
    private _items: IDetailsListGroupedLargeExampleItem[];
    private _columns: IColumn[];
    private _isMounted: boolean = false;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            includeDeleted: false,
            showDeleteDialog: false,
            goToAll: false,
            activeItem: null,
            unitId: this.props.match.params.unitId || 0,
            companyId: this.props.match.params.companyId,
            showUnitsWithoutPagesInSubpage: this.props.showUnitsWithoutPagesInOverview,
            totalCount: 0,
            groups: [],
            items: [],
            searchText: "",
        };

        this._columns = [
            {
                key: "name",
                name: "Name",
                fieldName: "name",
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
                onRender: (item) => {
                    item = item.data;
                    var count = item.userCount;
                    const menuCategories = this.actionMenuCategories(item);
                    const hasMenuItems = menuCategories.some((mc) => mc.items?.length > 0)

                    return (
                        <div
                            className={"list-item"}
                        >
                            <SpintrUser name={item.name} imageUrl={item.imageUrl} hideText personalName={false} />

                            <div className="title">
                                <SpintrLabel className="organisationTitle" as="h2" size="h6" weight="medium">
                                    {item.hasPage ? (
                                        <Link to={`/organisation/v/${item.id}`}>{item.name}</Link>
                                    ) : (
                                        <span>{item.name}</span>
                                    )}
                                </SpintrLabel>
                                <SpintrLabel className="organisationText" as="p" size="body-3">
                                    {`${count} ${localize(count !== 1 ? "Personer" : "Person").toLowerCase()}`}
                                </SpintrLabel>
                            </div>
                            {hasMenuItems && <ActionMenu categories={this.actionMenuCategories(item)} />}
                        </div>
                    );
                },
            },
        ];
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    public componentDidMount() {
        this._isMounted = true;
        this.fetchList();
        this.updateSideBar();
    }

    updateSideBar() {
        api.get("/api/v1/units", {
            params: { ignoreEmptyOffices: true, searchText: "", takeAll: true },
        }).then(response => {
            if (!this._isMounted) {
                return;
            }

            let children: IVisageSidebarMenuItem[] = [];

            if (response.data && response.data.offices && response.data.offices.length > 0) {
                children.push({
                    key: "organisation-offices",
                    name: localize("Kontor_plural"),
                    children: [
                        ...response.data.offices.map(organisation => {
                            return {
                                key: "organisation-office-" + organisation.id,
                                name: organisation.name,
                                id: organisation.id,
                                route: "/organisation/" + organisation.id,
                                routes: ["/organisation/" + organisation.id],
                            }
                        })
                    ]
                });
            }

            let items: IVisageSidebarMenuItem[] = [{
                key: "organisation",
                name: localize("Organisation"),
                icon: "hierarchy",
                activeIcon: "hierarchy",
                route: "/organisation",
                routes: ["/organisation"],
                // search: "",
                children,
                isExpanded: response.data && response.data.offices && response.data.offices.length > 0,
                isLoading: false,
                moduleKey: "departmenttop",
                activeMode: VisageSidebarMenuItemActiveMode.relative
            }];

            this.props.dispatch(setSidebarItems(items, "organisation"));
            this.props.dispatch(setSidebarMode(VisageSidebarMode.submenu));
            this.props.dispatch(expandToActiveItem());
        }).catch(() => { });
    }

    public componentDidUpdate(prevProps: IProps) {
        if (prevProps.match.params.unitId != this.props.match.params.unitId) {
            this.setState(
                {
                    unitId: this.props.match.params.unitId || 0,
                },
                () => this.fetchList()
            );
        }
    }

    public render() {
        if (this.state.isLoading) {
            return <SpintrLoader />;
        }

        return (
            <div>
                <div className="organisation-list-view">
                    <Helmet>
                        <title>{localize("Organisation")}</title>
                    </Helmet>
                    <Breadcrumbs displayInstance items={this.breadcrumbItems} />
                    <PageHeader
                        title={localize("Organisation")}
                        displaySearch
                        onSearchQueryChange={(value: string) => {
                            this.searchEvent(null, value);
                        }}
                    // actionMenuCategories={
                    //     this.props.isAdmin && [
                    //         {
                    //             items: [
                    //                 {
                    //                     text:
                    //                         this.state.unitId > 0
                    //                             ? `${localize("Skapa")} ${localize("NyAvdelning").toLowerCase()}`
                    //                             : localize("ByggDinOrganisation"),
                    //                     onClick: () => {
                    //                         if (this.state.unitId > 0) {
                    //                             this.props.history.push({
                    //                                 pathname: `/organisation/${this.state.unitId}/create`,
                    //                             });
                    //                         } else {
                    //                             this.props.history.push({
                    //                                 pathname: "/organisation/wizard",
                    //                             });
                    //                         }
                    //                     },
                    //                 },
                    //                 ...(this.state.unitId > 0
                    //                     ? [
                    //                           {
                    //                               text: localize("TaBortKontor"),
                    //                               onClick: () => {
                    //                                   this.setState({
                    //                                       showDeleteDialog: true,
                    //                                       goToAll: true,
                    //                                       activeItem: {
                    //                                           id: this.state.unitId,
                    //                                           isOffice: true,
                    //                                           departmentsCount: this.state.totalCount,
                    //                                       },
                    //                                   });
                    //                               },
                    //                           },
                    //                       ]
                    //                     : []),
                    //                 ...(this.state.unitId > 0 && !this.props.showUnitsWithoutPagesInOverview
                    //                     ? [
                    //                           {
                    //                               text: localize(
                    //                                   this.state.showUnitsWithoutPagesInSubpage
                    //                                       ? "DoljAvdelningarUtanSidor"
                    //                                       : "VisaAvdelningarUtanSidor"
                    //                               ),
                    //                               onClick: () => {
                    //                                   this.setState(
                    //                                       (prevState) => ({
                    //                                           showUnitsWithoutPagesInSubpage: !prevState.showUnitsWithoutPagesInSubpage,
                    //                                       }),
                    //                                       () => this.fetchList()
                    //                                   );
                    //                               },
                    //                           },
                    //                       ]
                    //                     : []),
                    //             ],
                    //         },
                    //     ]
                    // }
                    />
                    {this.state.activeItem && this.renderDeleteDialog()}
                    <ShimmeredDetailsList
                        isHeaderVisible={false}
                        groupProps={{
                            onRenderHeader: this._onRenderHeader,
                            showEmptyGroups: true,
                        }}
                        selectionMode={SelectionMode.none}
                        items={this.state.items}
                        groups={this.state.groups}
                        columns={this._columns}
                        onRenderDetailsHeader={this._onRenderDetailsHeader}
                    />
                    {!this.state.isLoading && this.state.groups.length == 0 && (
                        <div className="textNoTodo">
                            <SpintrLabel className="spintr-list-empty-list-label" as="p" size="body-2">
                                {localize("IngaPoster")}
                            </SpintrLabel>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    actionMenuCategories = (item) => [
        {
            items: [
                ...(item.hasPage
                    ? [
                        {
                            text: localize("Besok_singular"),
                            onClick: () => {
                                this.props.history.push({
                                    pathname: `/organisation/v/${item.id}`
                                });
                            },
                        },
                    ]
                    : []),
                ...(item.emailAddress
                    ? [
                        {
                            icon: "Mail",
                            text: item.emailAddress,
                            onClick: () => {
                                // @ts-ignore
                                window.location = `mailto:${item.emailAddress}`;
                            },
                        },
                    ]
                    : []),
                ...(item.phoneNumber
                    ? [
                        {
                            icon: "Phone",
                            text: item.phoneNumber,
                            onClick: () => {
                                // @ts-ignore
                                window.location = `tel:${item.phoneNumber}`;
                            },
                        },
                    ]
                    : []),
            ],
        },
        ...(this.props.isAdmin
            ? [
                {
                    title: localize("Redigera"),
                    items: [
                        {
                            text: localize("Redigera"),
                            onClick: () => {
                                this.props.history.push({
                                    pathname: this.props.enableCompanyLevel ?
                                        item.isCompany ? `/organisation/${item.id}/edit` : item.isOffice ? `/organisation/${item.companyId}/${item.id}/edit` : `/organisation/${item.companyId}/${item.officeId}/${item.id}/edit`
                                        : item.isOffice
                                            ? `/organisation/${item.id}/edit`
                                            : `/organisation/${item.officeId}/${item.id}/edit`,
                                });
                            },
                        },
                        {
                            text: localize("TaBort"),
                            onClick: () => {
                                this.setState({
                                    showDeleteDialog: true,
                                    activeItem: item,
                                });
                            },
                        },
                    ],
                },
            ]
            : []),
    ];

    renderDeleteDialog() {
        if (this.state.showDeleteDialog &&
            !this.state.activeItem.isOffice &&
            this.state.activeItem.userCount > 0) {
            return (
                <DeactivateDepartmentUsersModal
                    itemIdToDelete={this.state.activeItem.id}
                    onSuccess={() => {
                        this.setState((s) => ({
                            ...s,
                            showDeleteDialog: false,
                            goToAll: false,
                        }));

                        this.fetchList();
                    }}
                    onDismiss={() => {
                        this.setState((s) => ({
                            ...s,
                            showDeleteDialog: false,
                            goToAll: false,
                        }));
                    }}
                />
            )
        }

        return (
            <CustomDialog
                show={this.state.showDeleteDialog}
                title={this.state.activeItem.isOffice ? localize("TaBortKontor") : localize("TaBortAvdelning")}
                message={
                    this.state.activeItem.isOffice
                        ? this.state.activeItem.departmentsCount > 0
                            ? localize("DetGarBaraAttTaBortKontorSomInteHarNagraAvdelningar")
                            : localize("ArDuSakerPaAttDuVillTaBortKontoret") + "?"
                        : this.state.activeItem.userCount > 0
                            ? localize("DetGarBaraAttTaBortAvdelningarSomInteHaNagraAnvandare")
                            : localize("ArDuSakerPaAttDuVillTaBortAvdelningen") + "?"
                }
                onConfirm={() => {
                    if (this.state.activeItem.isOffice) {
                        this.setState({
                            showDeleteDialog: false,
                            goToAll: false,
                        });

                        if (this.state.activeItem.departmentsCount > 0) {
                            this.setState({
                                showDeleteDialog: false,
                                goToAll: false,
                            });
                            return;
                        }

                        this.setState({ isLoading: true }, () => {
                            api
                                .delete(`/api/v1/offices/${this.state.activeItem.id}`)
                                .then(() => {
                                    if (this.state.goToAll) {
                                        this.props.history.push({
                                            pathname: "/organisation",
                                        });
                                    } else {
                                        this.fetchList();
                                    }
                                })
                                .catch((e) => {
                                    alert("Kunde inte ta bort verksamheten"); // FIXME
                                    this.setState({ isLoading: false });
                                    this.props.history.push("/organisation")
                                });
                        });
                    } else {
                        this.setState({
                            showDeleteDialog: false,
                        });

                        if (this.state.activeItem.userCount > 0) {
                            return;
                        }

                        this.setState({ isLoading: true }, () => {
                            api.delete(`/api/v1/departments/${this.state.activeItem.id}`).then(() => {
                                this.fetchList();
                            });
                        });
                    }
                }}
                onDismiss={() => {
                    this.setState({
                        showDeleteDialog: false,
                        goToAll: false,
                    });
                }}
            />
        );
    }

    breadcrumbItems = [
        {
            key: "org",
            text: localize("Organisation"),
            link: "/organisation",
        },
    ];

    submenuSearchOptions = { enabled: true };

    fetchSubmenu = (params: submenuQueryParams) => {
        return new Promise((resolve, reject) => {
            api
                .get("/api/v1/units", {
                    params: { ignoreEmptyOffices: true, searchText: params.searchText, takeAll: true },
                })
                .then((response: AxiosResponse) => {
                    var offices = [
                        {
                            name: localize("Alla"),
                            id: 0,
                            active: this.state.unitId == 0,
                            url: "/organisation",
                        },
                        ...response.data.offices.map((office) => {
                            return {
                                name: office.name,
                                id: office.id,
                                active: office.id == this.state.unitId,
                                url: `/organisation/${office.id}`,
                            };
                        }),
                    ];

                    resolve(offices);
                });
        });
    };

    makeGroupsAndItems = (organisation) => {
        const { unitId, companyId } = this.state
        let groups = [];
        let items = [];
        let startIndex = 0;

        organisation.offices.map((office, i) => {
            if (
                ((unitId && office.id == unitId) || !unitId || unitId === 0) &&
                ((companyId && office.companyId == companyId) || !companyId || companyId === 0)
            ) {
                groups.push({
                    key: i.toString(),
                    name: office.name,
                    startIndex,
                    count: office.departments.length,
                    level: 0,
                    isCollapsed: unitId ? false : this.props.collapsibleUnitsList ? true : false,
                    data: office,
                });

                office.departments.map((dept, i2) => {
                    items.push({
                        key: dept.id,
                        name: dept.name,
                        data: { ...dept, officeId: office.id, companyId: office.companyId },
                    });
                });

                startIndex += office.departments.length;
            }
        });
        return { groups, items };
    };

    fetchList = () => {
        const getCompanies = !this.state.companyId && !this.state.unitId && this.props.enableCompanyLevel;

        if (getCompanies) {
            this.setState({ isLoading: true }, () => {
                api
                    .get("/api/v1/companies", {
                        params: {
                            limit: 1000,
                            includeDeleted: false,
                            filter: this.state.searchText,
                        },
                    })
                    .then((response: AxiosResponse) => {
                        var groups = response.data.items.map((company) => ({
                            key: company.id,
                            name: company.name,
                            startIndex: 0,
                            count: company.officeCount,
                            level: 0,
                            isCollapsed: false,
                            data: {
                                ...company,
                                isCompany: true
                            },
                        }));

                        var items = [];

                        this.setState({ groups, items, isLoading: false });
                    });
            });
            return;
        }

        this.setState({ isLoading: true }, () => {
            api
                .get("/api/v1/units", {
                    params: {
                        take: 1000,
                        flatMode: false,
                        hierarchy: true,
                        showUnitsWithoutPages: true,
                        // unitId: this.state.unitId,
                        searchText: this.state.searchText,
                    },
                })
                .then((response: AxiosResponse) => {
                    const { groups, items } = this.makeGroupsAndItems(response.data);
                    this.setState({ groups, items, isLoading: false });
                });
        });
    };

    private _onRenderDetailsHeader(props: IDetailsHeaderProps) {
        return <DetailsHeader {...props} ariaLabelForToggleAllGroupsButton={"Expand collapse groups"} />;
    }

    private _onRenderHeader = (props: IGroupHeaderProps): JSX.Element => {
        const toggleCollapse = (): void => {
            props.onToggleCollapse!(props.group!);
        };

        const { group } = props;

        return (
            <div className="heading" role="row">
                <div className="icon" role="cell">
                    {group.count > 0 && !group.data.isCompany && (
                        <UnstyledButton onClick={toggleCollapse} title={group.isCollapsed ? "Expand" : "Collapse"}>
                            <Visage2Icon icon={group.isCollapsed ? "add" : "minus"} />
                        </UnstyledButton>
                    )}
                </div>
                <SpintrUser name={group!.name} imageUrl={group?.data.imageUrl} hideText personalName={false} />

                <div className="title" role="cell">
                    <SpintrLabel className="organisationTitle" as="h2" size="h6" weight="medium">
                        {group.data.isCompany ? (
                            <Link to={`/organisation/c/${group.data.id}`}>{group.name}</Link>
                        ) : group.data.hasPage ? (
                            <Link to={`/organisation/v/${group.data.id}`}>{group.name}</Link>
                        ) : (
                            <span>{group.name}</span>
                        )}
                    </SpintrLabel>
                    <SpintrLabel className="organisationText" as="p" size="body-3">
                        {`${group.count} ${localize(
                            group.count !== 1
                                ? group.data.isCompany
                                    ? "Kontor_plural"
                                    : "Avdelningar"
                                : group.data.isCompany
                                    ? "Kontor"
                                    : "Avdelning"
                        ).toLowerCase()}`}
                    </SpintrLabel>
                </div>
                <ActionMenu categories={this.actionMenuCategories(group.data)} />
            </div>
        );
    };

    debouncedFetchSearch = debounce(() => this.fetchList(), 500);

    searchEvent = (event: React.ChangeEvent, value: string): void => {
        this.setState(
            {
                searchText: value,
            },
            this.debouncedFetchSearch
        );
    };
}

const mapStateToProps = (state: IApplicationState, props: IProps) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        enableCompanyLevel: state.instance.get("enableCompanyLevel"),
        showUnitsWithoutPagesInOverview: state.instance.get("showUnitsWithoutPagesInOverview"),
        collapsibleUnitsList: state.instance.get("collapsibleUnitsList"),
    };
};

export default connect(mapStateToProps)(OrganisationListView);
