import React, { CSSProperties, Fragment, ReactElement, useCallback, useMemo, useRef, useState } from "react";
import { Conditional } from "src/components/Conditional";
import { CaptionBody, Colors } from "src/components/Text";
import { useHover } from "src/hooks/useHover";
import { TimelineBarProps, TimelineBarMutableState } from "./TimelineBar.types";
import { ColumnDuration } from "../types";
import moment from "moment";
import getLightOrDarkColorBasedOnColor from "src/utils/getLightOrDarkColorBasedOnColor";
import { isParentOf } from "src/utils";
import { ConditionalRender } from "src/components/ConditionalRender";
import classNames from "classnames";


function calculateBarStyle(
    timelineWidth: number,
    timelineDuration: ColumnDuration,
    start: number,
    end: number
): [string, CSSProperties] {
    const timeSinceStart = start - timelineDuration.startMilliseconds;
    const left = (timeSinceStart / timelineDuration.totalMilliseconds) * timelineWidth;

    const timeUntilEnd = timelineDuration.endMilliseconds - end;
    const right = (timeUntilEnd / timelineDuration.totalMilliseconds) * timelineWidth;

    const width = timelineWidth - left - right;

    return [(width <= 24 ? "minimized" : ""), {
        height: "42px",
        left: `${left}px`,
        right: `${right}px`,
    }];
}

function snapToGrid(date: Date): Date {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const milliseconds = date.getMilliseconds();

    if (!(hours > 12 || (hours === 12 && (minutes > 0 || seconds > 0 || milliseconds > 0)))) {
        date.setDate(date.getDate() - 1);
    }
    date.setHours(23, 59, 59, 999);

    return date;
}

function formatDates(startMs: number, endMs: number): string {
    const startMoment = moment(startMs);
    const endMoment = moment(endMs);

    let retVal: string = startMoment.format("D");
    if (startMoment.year() !== endMoment.year() || startMoment.month() !== endMoment.month()) {
        retVal += ` ${startMoment.format("MMM")}`;
    }

    retVal += ` - ${endMoment.format("D MMM")}`;

    return retVal;
}

function TimelineBar(props: TimelineBarProps): ReactElement {
    const {
        axisWidth,
        item,
        onBarClick,
        onItemDurationChange,
        timelineWidth,
        timelineDuration,
        todayTime,
    } = props;

    const [hoverRef, isHoveringBar] = useHover<HTMLDivElement>();
    const refState = useRef<TimelineBarMutableState>({ mouseOffsetX: 0, resizing: "no" });
    const [_, setForcedUpdate] = useState<boolean>(false);

    const onMouseMove = useCallback((event: MouseEvent) => {
        if (!hoverRef.current || refState.current.resizing === "no") {
            return;
        }

        let scrollParent: HTMLElement = hoverRef.current;
        while (!scrollParent.classList.contains("timeline") && scrollParent.parentElement) {
            scrollParent = scrollParent.parentElement;
        }

        if (!scrollParent) {
            return;
        }

        const mouseX = event.clientX;
        const axisOfftet = axisWidth || 257;

        const scrollParentRect = scrollParent.getBoundingClientRect();
        const scrollParentLeft = scrollParentRect.left
        const scrollLeft = scrollParent.scrollLeft - axisOfftet; // remove axis width
        const positionInTimeline = Math.max(0, mouseX - scrollParentLeft + scrollLeft);
        
        const percentage = positionInTimeline / timelineWidth;

        const newDate = new Date(timelineDuration.startMilliseconds + timelineDuration.totalMilliseconds * percentage);
        const snappedDate = snapToGrid(newDate);

        if (refState.current.resizing === "right") {
            const minTime = item.start.getTime() + (1000 * 60 * 60 * 24) - 1;

            if (snappedDate.getTime() < minTime) {
                snappedDate.setTime(minTime);
            }

            refState.current.overriddenEndMs = snappedDate.getTime();
        } else if (refState.current.resizing === "left") {
            const maxTime = item.end.getTime() - (1000 * 60 * 60 * 24) + 1;

            if (snappedDate.getTime() > maxTime) {
                snappedDate.setTime(maxTime);
            }

            refState.current.overriddenStartMs = snappedDate.getTime();
        } else if (refState.current.resizing === "both") {
            const timeOffset = refState.current.mouseOffsetX <= 0
                ? 0
                : Math.round((refState.current.mouseOffsetX / timelineWidth) * timelineDuration.totalMilliseconds);

            let snapDate = snappedDate;
            if (timeOffset !== 0) {
                snapDate = snapToGrid(new Date(newDate.getTime() - timeOffset));
            }

            refState.current.overriddenStartMs = snapDate.getTime();

            const duration = (item.end.getTime() - item.start.getTime());
            refState.current.overriddenEndMs = refState.current.overriddenStartMs + duration;
        }

        if (mouseX < scrollParentRect.left + (100 + axisOfftet)) {
            scrollParent.scrollTo({ left: scrollParent.scrollLeft - 100, behavior: "smooth" });
        } else if (mouseX > scrollParentRect.right - 100) {
            scrollParent.scrollTo({ left: scrollParent.scrollLeft + 100, behavior: "smooth" });
        }

        setForcedUpdate((prev) => !prev);
    }, [refState, hoverRef.current, timelineDuration, timelineWidth, item.start, item.end, setForcedUpdate, axisWidth]);

    const onMouseUp = useCallback((_: MouseEvent) => {
        const { overriddenStartMs, overriddenEndMs } = refState.current;
        
        const updatedStart = overriddenStartMs ?? item.start.getTime();
        const updatedEnd = overriddenEndMs ?? item.end.getTime();

        refState.current.mouseOffsetX = 0;
        refState.current.overriddenEndMs = refState.current.overriddenStartMs = undefined;

        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mouseup", onMouseUp);

        refState.current.resizing = "no";
        setForcedUpdate((prev) => !prev);
        setTimeout(() => {
            refState.current.resizeStartMs = undefined;
        }, 100);

        if (updatedStart === item.start.getTime() && updatedEnd === item.end.getTime()) {
            return;
        }

        onItemDurationChange?.(item, updatedStart, updatedEnd);
    }, [refState, onMouseMove, setForcedUpdate, item, onItemDurationChange]);

    const onResizeStart = useCallback((event: React.MouseEvent, direction: "left" | "right" | "both") => {
        if (!event) {
            return;
        }

        event.preventDefault?.();
        event.stopPropagation?.();
        
        refState.current.resizeStartMs = Date.now();
        refState.current.resizing = direction;

        if (direction === "both" && hoverRef.current) {
            if (!isParentOf(hoverRef.current, event.target as HTMLElement)) {
                return;
            }

            const barRect = hoverRef.current.getBoundingClientRect();
            const mouseOffsetX = event.clientX - barRect.left;
            refState.current.mouseOffsetX = mouseOffsetX;
        }

        window.addEventListener("mousemove", onMouseMove);
        window.addEventListener("mouseup", onMouseUp);
    }, [refState, onMouseUp, onMouseMove, hoverRef]);

    const onBarClicked = useCallback(() => {
        const now = Date.now();

        if (refState.current.resizeStartMs && now - refState.current.resizeStartMs > 300) {
            return;
        }

        onBarClick?.(item)
    }, [item, refState, onBarClick]);

    const onLeftHandleDragStart = useCallback((event: React.MouseEvent) => onResizeStart(event, "left"), [onResizeStart]);
    const onRightHandleDragStart = useCallback((event: React.MouseEvent) => onResizeStart(event, "right"), [onResizeStart]);
    const onBarDragStart = useCallback((event: React.MouseEvent) => onResizeStart(event, "both"), [onResizeStart]);

    const [barClass, barStyle] = useMemo<[string, CSSProperties]>(() => calculateBarStyle(
        timelineWidth,
        timelineDuration,
        refState.current.overriddenStartMs ?? item.start.getTime(),
        refState.current.overriddenEndMs ?? item.end.getTime(),
    ), [timelineWidth, timelineDuration, item, refState.current.overriddenEndMs, refState.current.overriddenStartMs]);

    const originStyle = useMemo<CSSProperties>(() => refState.current.resizing !== "both" ? {} : {
        ...calculateBarStyle(
            timelineWidth,
            timelineDuration,
            refState.current.overriddenStartMs ?? item.start.getTime(),
            refState.current.overriddenEndMs ?? item.end.getTime(),
        )[1],
        backgroundColor: item.color + "80",
    }, [timelineWidth, timelineDuration, item, refState.current.resizing]);

    const progressStyle = useMemo<CSSProperties>(() => {
        const cssProperties: CSSProperties = {
            backgroundColor: item.color,
        };

        const startTime = refState.current.overriddenStartMs ?? item.start.getTime();
        if (startTime > todayTime) {
            return cssProperties;
        }

        const endTime = refState.current.overriddenEndMs ?? item.end.getTime();
        if (todayTime > endTime) {
            cssProperties.borderTopRightRadius = "8px";
            cssProperties.borderBottomRightRadius = "8px";
            cssProperties.right = 0;
            return cssProperties;
        }

        const timeSinceStart = todayTime - startTime;
        const pixelWidth = (timeSinceStart / timelineDuration.totalMilliseconds) * timelineWidth;

        cssProperties.width = `${pixelWidth + 1}px`;

        return cssProperties;
    }, [item, todayTime, refState.current.overriddenEndMs, refState.current.overriddenStartMs, timelineWidth, timelineDuration.totalMilliseconds]);

    const textColor = useMemo<Colors>(
        () => getLightOrDarkColorBasedOnColor(
            item.color,
            "white",
            "contentDark"
        ) as Colors,
        [item.color],
    );

    const dateColor = useMemo<Colors>(
        () => getLightOrDarkColorBasedOnColor(
            item.color,
            "white",
            "contentNormal"
        ) as Colors,
        [item.color],
    );

    return (
        <Fragment>
            <div
                className={classNames("TimelineBar", barClass, { "hover": isHoveringBar })}
                ref={hoverRef}
                style={barStyle}
                title={item.name}
            >
                <div
                    className="TimelineBar-inner"
                    onClick={onBarClicked}
                    onMouseDown={onBarDragStart}
                    style={{ backgroundColor: item.color + "99" }}
                >
                    <CaptionBody
                        className="TimelineBar-label-top"
                        color={textColor}
                        weight="medium"
                    >
                        {props.item.name}
                    </CaptionBody>
                    <div className="TimelineBar-label-bottom">
                        <CaptionBody color={dateColor} weight="regular">
                            {formatDates(
                                refState.current.overriddenStartMs ?? item.start.getTime(),
                                refState.current.overriddenEndMs ?? item.end.getTime()
                            )}
                        </CaptionBody>
                    </div>
                </div>

                <div className="TimelineBar-progressTracker" style={progressStyle} />

                <Conditional condition={isHoveringBar || refState.current.resizing !== "no"}>
                    <div
                        className="TimelineBar-barHandle left"
                        onMouseDown={onLeftHandleDragStart} />
                    <div
                        className="TimelineBar-barHandle right"
                        onMouseDown={onRightHandleDragStart} />
                </Conditional>
            </div>
            <ConditionalRender condition={refState.current.resizing === "both"}>
                {() => <div className="TimelineBar-origin" style={originStyle} />}
            </ConditionalRender>
        </Fragment>
    );
}

export default TimelineBar;
