import React, { Component, ReactNode } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import { localize } from 'src/l10n';
import { VisageSidebarDrawerId, VisageSidebarMenuItemActiveMode, VisageSidebarMode, setSidebarMode } from 'src/sidebar';
import { IVisageSidebarMenuItem } from 'src/sidebar/reducer';
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from 'src/typings';
import getLightOrDarkColorBasedOnColor from 'src/utils/getLightOrDarkColorBasedOnColor';
import './VisageSidebar.scss';
import { ConversationsPanel } from 'src/chat/components/ConversationsPanel';
import VisageSidebarItems from './VisageSidebarItems';
import Color from 'src/style/colors/Color';
import VisageSidebarModeChanger from './VisageSidebarModeChanger';
import classNames from 'classnames';
import { IActiveUserProfile } from 'src/profile/reducer';
import { StartPageBuilderDrawer } from 'src/spintr/components/StartPageBuilderDrawer';
import { fetchMenu } from 'src/sidebar/utils';
import SpintrUserMenu from 'src/spintr/components/SpintrUserMenu';
import { VisageSidebarBackButton } from './VisageSidebarBackButton';
import { IAppsState } from 'src/app/reducer';

interface IProps {
    sidebarMode: VisageSidebarMode,
    drawerId: VisageSidebarDrawerId,
    logoUrl: string;
    colorLogoUrl: string;
    logoTitle: string;
    currentUser: IActiveUserProfile;
    isAdmin?: boolean;
    isEditor?: boolean;
    dispatch: any;
    accentColor?: string;
    useColorHeader?: boolean;
    instance?: any;
    runIntroduction?: any;
    app?: IAppsState;
    visage2FullscreenMode?: boolean;
}

class VisageSidebar extends Component<IProps> {
    public componentDidMount = () => {
        fetchMenu(this.props.dispatch, this.props.currentUser, this.props.instance, this.props.app.items);
    }

    public renderLogo(): ReactNode {
        let logoUrl;
        let color = "#FFFFFF";

        if (this.props.useColorHeader) {
            color = this.props.instance.get("useSecondaryColorHeader") ?
                this.props.instance.get("headerSecondaryColor") :
                this.props.instance.get("headerColor");

            if (!color) {
                color = "#FFFFFF";
            }
        }

        let preferliblyUseLightLogo = getLightOrDarkColorBasedOnColor(color,
            "a",
            "b") === "a";

        if (preferliblyUseLightLogo) {
            logoUrl = !!this.props.logoUrl ?
                this.props.logoUrl :
                this.props.colorLogoUrl;
        } else {
            logoUrl = !!this.props.colorLogoUrl ?
                this.props.colorLogoUrl :
                this.props.logoUrl;
        }

        if (color === "#FFFFFF" &&
            logoUrl === "/Style/Images/specific/logomodern.png") {
            logoUrl = this.props.logoUrl;
        }

        let maxHeight = 59;

        // if (!!this.props.headerHeight) {
        //     maxHeight += this.props.headerHeight;
        // }

        return (
            <div className={"logo-wrapper" + (color === "#FFFFFF" ? " logo-wrapper-white" : "")} style={{
                borderBottomColor: color === "#FFFFFF" ? Color.fromHex(this.props.instance.get("color")).customLighten(90).toString("hex") : "transparent",
                borderBottomWidth: color === "#FFFFFF" ? 1 : 0,
                backgroundColor: color
            }}>
                <Link to="/">
                    <div className="logo-helper" />
                    <img src={logoUrl} />
                </Link>
            </div>
        );
    }

    shouldComponentUpdate(nextProps: IProps) {
        return this.props.sidebarMode !== nextProps.sidebarMode ||
            this.props.drawerId !== nextProps.drawerId ||
            this.props.logoUrl !== nextProps.logoUrl ||
            this.props.colorLogoUrl !== nextProps.colorLogoUrl ||
            nextProps.instance.get("backgroundType") !== this.props.instance.get("backgroundType") ||
            nextProps.instance.get("useSecondaryColorHeader") !== this.props.instance.get("useSecondaryColorHeader") ||
            nextProps.instance.get("headerSecondaryColor") !== this.props.instance.get("headerSecondaryColor") ||
            nextProps.colorLogoUrl !== this.props.colorLogoUrl ||
            nextProps.logoUrl !== this.props.logoUrl ||
            nextProps.visage2FullscreenMode !== this.props.visage2FullscreenMode;
    }

    renderDrawerContent() {
        if (this.props.drawerId === VisageSidebarDrawerId.groups || this.props.drawerId === VisageSidebarDrawerId.messages) {
            return (
                <ConversationsPanel fullscreen />
            )
        }

        return null;
    }

    public render(): ReactNode {
        const drawerEnabled = (
            !!this.props.drawerId &&
            this.props.drawerId !== VisageSidebarDrawerId.none &&
            (
                this.props.sidebarMode === VisageSidebarMode.drawer ||
                this.props.sidebarMode === VisageSidebarMode.scopedDrawer
            )
        );

        return (
            <div className={"VisageSidebarContainer"}>
                <VisageSidebarModeChanger />
                <div className={"VisageSidebar"}>
                    {this.props.visage2FullscreenMode && this.renderLogo()}
                    {this.props.sidebarMode !== VisageSidebarMode.noMenu && (
                        <>
                            <div className={classNames("VisageSidebar-inner", {
                                isGroupUser: !!this.props.currentUser.isGroupUser
                            })}>
                                <VisageSidebarItems />
                            </div>
                            {drawerEnabled && (
                                <div className={classNames("VisageSidebar-drawer", {
                                    isGroupUser: !!this.props.currentUser.isGroupUser
                                })}>
                                    {this.renderDrawerContent()}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    sidebarMode: state.sidebar.mode,
    drawerId: state.sidebar.drawerId,
    logoUrl: state.instance.get("logoUrl"),
    colorLogoUrl: state.instance.get("colorLogoUrl"),
    logoTitle: state.instance.get('name'),
    currentUser: state.profile.active,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    accentColor: state.instance.get('accentColor'),
    useColorHeader: state.instance.get('useColorHeader'),
    instance: state.instance,
    app: state.app,
    visage2FullscreenMode: state.ui.visage2FullscreenMode
});

export default connect(mapStateToProps)(VisageSidebar);