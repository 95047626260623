import React, { ReactElement, useCallback, useRef, useState } from "react";
import { PlannerTimelineItem, PlannerViewMode, TimelineMode } from "../types";
import { PlannerProps, PlannerState } from "./Planner.types";
import { Timeline } from "../Timeline";
import { PlannerCommandBar } from "../PlannerCommandBar";
import { PlannerItemFormDrawer } from "../PlannerItemFormDrawer";
import { Conditional } from "src/components/Conditional";
import { PlannerList } from "../PlannerList";
import { PlannerItemDetailDrawer } from "../PlannerItemDetailDrawer";

function Planner(props: PlannerProps): ReactElement {
    const [state, setState] = useState<PlannerState>({
        drawerOpen: false,
        searchText: "",
        timelineMode: props.initialTimelineMode || "WEEKS",
        viewMode: props.initialViewMode || "TIMELINE",
    });

    const { timelineMode, viewMode } = state;
    const refetchRef = useRef<() => void>(null);

    const onSearchChanged = useCallback((searchText: string) => setState((prevState) => ({
        ...prevState,
        searchText,
    })), [setState]);

    const onTimelineModeChanged = useCallback(
        (mode: TimelineMode) => setState((prevState) => ({
            ...prevState,
            timelineMode: mode,
        })),
        [setState],
    );

    const onViewModeChange = useCallback(
        (mode: PlannerViewMode) => setState((prevState) => ({
            ...prevState,
            viewMode: mode,
        })),
        [setState],
    );

    const onOpenDrawer = useCallback(
        (id?: number | undefined) => setState((prevState) => ({
            ...prevState,
            drawerOpen: true,
            selectedId: id,
        })),
        [setState],
    );

    const onDrawerDismissed = useCallback(
        (created?: boolean | undefined) => setState((prevState) => ({
            ...prevState,
            drawerOpen: false,
        })),
        [setState]
    );

    const onDetailsDismissed = useCallback(() => setState((prevState) => ({
        ...prevState,
        selectedId: undefined
    })), [setState]);

    const onEditClicked = useCallback(
        (id: number) => {
            onOpenDrawer(id);
            onDetailsDismissed();
        },
        [onDetailsDismissed, onOpenDrawer],
    );

    const onBarClicked = useCallback(
        (item: PlannerTimelineItem<number>) =>setState((prevState) => ({ ...prevState, selectedId: item.key })),
        [setState],
    );

    return (
        <section className="Planner">
            <PlannerCommandBar
                onCreateClick={onOpenDrawer}
                onSearchChange={onSearchChanged}
                onTimelineModeChange={onTimelineModeChanged}
                onViewModeChange={onViewModeChange}
                searchText={state.searchText}
                timelineMode={timelineMode}
                viewMode={viewMode} />

            <Conditional condition={viewMode === "LIST"}>
                <PlannerList
                    refetchRef={refetchRef} />
            </Conditional>

            <Conditional condition={viewMode === "TIMELINE"}>
                <Timeline
                    onBarClick={onBarClicked}
                    searchText={state.searchText}
                    timelineMode={timelineMode} />
            </Conditional>

            <PlannerItemFormDrawer
                itemId={state.selectedId}
                onDismiss={onDrawerDismissed}
                open={state.drawerOpen} />

            <PlannerItemDetailDrawer
                onDismiss={onDetailsDismissed}
                onEditClick={onEditClicked}
                open={!!state.selectedId} 
                selectedId={state.selectedId} />

        </section>
    );
}

export default Planner;
