import moment from "moment";
import { CommandBar, IContextualMenuItem } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import { Breadcrumbs, Loader, PageHeader } from "src/ui";
import CustomTimeline from "src/ui/components/Timeline/CustomTimeline";
import getInterval from "./ResourceInterval";
import "./ResourceView.scss";
import api from "src/spintr/SpintrApi";
import { IVisageSidebarMenuItem, VisageSidebarMenuItemActiveMode, VisageSidebarMode } from "src/sidebar";
import { expandToActiveItem, setSidebarItems, setSidebarMode } from "src/sidebar/actions";

interface IProps {
    history: any;
    isAdmin: boolean;
    isEditor: boolean;
    instance: any;
    dispatch?: any;
}

interface IState {
    isLoading: boolean;
    startDate: string;
    itemsToShow: any;
    resources: any;
    target: any;
    item: any;
    user: any;
    listCategories: any;
}

class ResourceBookingView extends Component<IProps, IState> {
    private _isMounted: boolean = false;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            startDate: "",
            itemsToShow: [],
            resources: [],
            target: "0",
            item: "",
            user: "",
            listCategories: [],
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ isLoading: true });
        let defaultTimeStart = moment.utc().startOf("month").toDate();
        let defaultTimeEnd = moment.utc().startOf("month").add(1, "month").toDate();

        let params = {
            after: defaultTimeStart,
            before: defaultTimeEnd,
            skip: 0,
            take: 10,
        };
        api
            .get("/api/bookingresourcecategories")
            .then((response) => this.setState({ listCategories: response.data }));

        api
            .get("/api/bookingresources", { params: params })
            .then((response) => this.getIntervalFromResources(response));

        this.updateSideBar();
    }

    updateSideBar() {
        api.get(`/api/bookingresourcecategories`).then(response => {
            if (!this._isMounted) {
                return;
            }

            let children: IVisageSidebarMenuItem[] = [];

            for (let c of response.data) {
                if (!!c.resources && c.resources.length > 0) {
                    children.push({
                        key: "resource-category-" + c.id,
                        name: c.name,
                        children: c.resources.map((r: any) => {
                            return {
                                key: "reosurce-" + r.id,
                                name: r.name,
                                id: r.id,
                                route: "/booking/" + c.id + "/" + r.id,
                                routes: ["/booking/" + c.id + "/" + r.id],
                            }
                        })
                    })
                }
            }

            children = [
                {
                    key: "resources-all",
                    name: localize("Alla"),
                    route: "/booking",
                    routes: ["/booking"],
                },
                ...(!!children && children.length > 0 ? children : [])
            ];

            let items: IVisageSidebarMenuItem[] = [{
                key: "bookings",
                name: localize("Resursbokning"),
                icon: "Booking",
                iconStyle: "Custom",
                activeIcon: "Location",
                // route: "/booking",
                // search: "",
                children,
                isExpanded: !!children && children.length > 0,
                isLoading: false,
                moduleKey: "bookings",
                // routes: ["/booking"],
                // activeMode: VisageSidebarMenuItemActiveMode.relative
            }];

            this.props.dispatch(setSidebarItems(items, "bookings"));
            this.props.dispatch(setSidebarMode(VisageSidebarMode.submenu));
            this.props.dispatch(expandToActiveItem());
        }).catch(() => { });
    }

    getIntervalFromResources(response) {
        let intervals = [];
        let resourcesDays = [];

        response.data.map((obj) =>
            obj.intervals.map((item) => {
                intervals.push({
                    group: obj.id,
                    id: item.id,
                    startHour: item.start,
                    endHour: item.end,
                    bgColor: "#D5F3E7",
                    availability: item.availability,
                    bookings: item.bookings,
                });
            })
        );

        let uniqueItems = intervals.filter(
            (v, i, a) => a.findIndex((t) => t.availability === v.availability && t.group === v.group) === i
        );

        resourcesDays = uniqueItems.map((obj) => getInterval(obj, this.state.startDate));
        let allResourcesDays = resourcesDays.flat();

        this.setState({
            isLoading: false,
            resources: response.data,
            itemsToShow: allResourcesDays,
        });
    }

    loadNewItems(startdate?, enddate?) {
        if (!startdate) {
            this.setState({ isLoading: true });
        }

        let defaultTimeStart = moment.utc().startOf("month").toDate();
        let defaultTimeEnd = moment.utc().startOf("month").add(1, "month").toDate();
        if (startdate) {
            defaultTimeStart = moment.utc(startdate).toDate();
        }
        if (enddate) {
            defaultTimeEnd = moment.utc(enddate).toDate();
        }
        let params;
        if (this.state.target === "0") {
            params = {
                after: defaultTimeStart,
                before: defaultTimeEnd,

                skip: 0,
                take: 10,
            };
        } else {
            params = {
                after: defaultTimeStart,
                before: defaultTimeEnd,
                categoryId: this.state.target,
                skip: 0,
                take: 10,
            };
        }

        api
            .get("/api/bookingresources", { params: params })
            .then((response) => this.getIntervalFromResources(response));
    }

    showResource = (resource) => {
        this.props.history.push(`/booking/${resource.categoryId}/${resource.id}`);
    };

    checkMonth = (startdate, enddate) => {
        this.setState({ startDate: startdate });
        this.loadNewItems(startdate, enddate);
    };

    showInfo = (item) => {
        const findResource = (resourceId) => {
            return this.state.resources.find((r) => {
                return r.id === resourceId;
            });
        };

        const resource = findResource(item.group);
        const year = moment.utc(item.start_time).year();
        const week = moment.utc(item.start_time).isoWeek();
        this.props.history.push(`/booking/${resource.categoryId}/${resource.id}/${year}/${week}`);
    };

    changeTarget = (id) => {
        let today = moment.utc().format("YYYY-MM-DD");

        this.setState(
            (state) => ({
                ...state,
                target: `${id}`,
            }),
            () => {
                this.setState({ startDate: today }, () => this.loadNewItems());
            }
        );
    };

    public render(): ReactNode {
        const header = localize("Resursbokning");
        let categories = [
            {
                key: "0",
                text: localize("Alla"),
                onClick: () => {
                    this.changeTarget("0");
                },
            } as IContextualMenuItem,
        ];

        this.state.listCategories
            .filter((c) => {
                return c.resources?.length > 0;
            })
            .map((obj) => {
                categories.push({
                    key: `${obj.id}`,
                    text: obj.name,
                    onClick: () => {
                        this.changeTarget(obj.id);
                    },
                } as IContextualMenuItem);
            });

        return (
            <div className="ResourceBookingView">
                <Helmet>
                    <title>{localize("Resursbokning")}</title>
                </Helmet>
                <Breadcrumbs
                    displayInstance
                    items={[
                        {
                            text: localize("Resursbokning"),
                            key: 0,
                            link: "/booking",
                        },
                    ]}
                />
                <div>
                    <PageHeader
                        title={header}
                        helpTextId={7}
                        buttons={[
                            {
                                text: localize("Avdelning"),
                                subMenuProps: {
                                    items: categories
                                }
                            },
                            ...(this.props.isAdmin || this.props.isEditor
                                ? [
                                    {
                                        key: "add",
                                        text: localize("SkapaResurs"),
                                        onClick: () => {
                                            this.props.history.push({
                                                pathname: `/booking/create`,
                                            });
                                        },
                                        iconProps: { iconName: "Add" },
                                        className: "commandBarAddButton",
                                        theme: "primary"
                                    },
                                ]
                                : [])
                        ]}
                    />
                    {this.state.isLoading ? (
                        <Loader />
                    ) : (
                        <CustomTimeline
                            onClickGroup={this.showResource}
                            whichMonth={this.checkMonth}
                            showInfo={this.showInfo}
                            items={this.state.itemsToShow}
                            users={this.state.resources}
                            instance={this.props.instance}
                        />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    instance: state.instance,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    smallViewMode: state.ui.isSmallViewMode,
});

export default withRouter(connect(mapStateToProps)(ResourceBookingView));
