export function findRelativeParent(element: HTMLElement): HTMLElement | undefined {
    return parentBy(element, (el) => {
        const style = window.getComputedStyle(el, null);
        if (!style || !style.position) {
            return false;
        }

        return ["absolute", "relative"].indexOf(style.position) > -1;
    });
}

export function getParents(element: HTMLElement): HTMLElement[] {
    const parents: HTMLElement[] = [];

    while (element.parentElement) {
        element = element.parentElement;

        parents.push(element);
    }

    return parents;
}

export function isParentOf(parent: HTMLElement, child: HTMLElement): boolean {
    let el = child;
    while (el.parentElement) {
        el = el.parentElement;

        if (el === parent) {
            return true;
        }
    }

    return false;
}

export function getSharedParent(el1: HTMLElement, el2: HTMLElement): HTMLElement | undefined {
    const p1 = getParents(el1);
    const p2 = getParents(el2);

    return p1.find((p) => p2.indexOf(p) > -1);
}

export type ParentByPredicate = (element: HTMLElement) => boolean;
export function parentBy(element: HTMLElement, predicate: ParentByPredicate): HTMLElement | undefined {
    let el = element;
    while (el.parentElement) {
        el = el.parentElement;

        if (!predicate(el)) {
            continue;
        }

        return el;
    }

    return undefined;
}
