import { AxiosResponse } from "axios";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { ActionMenu, Label, PageHeader, SpintrUser } from "src/ui";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import SuppliersBreadcrumbs from "./SuppliersBreadcrumbs";
import "./SuppliersView.scss";
import api from "src/spintr/SpintrApi";
import { updateSidebar } from "./utils";

interface IProps {
    instance?: any;
    currentUser?: any;
    history?: any;
    isAdmin?: boolean;
    isEditor?: boolean;
    appName?: string;
    dispatch?: any;
}

interface IState {
    isLoading: boolean;
    suppliers: any[];
    categoryId?: any;
    tagId?: any;
    category?: any;
    tag?: any;
    displayDeleted: boolean;
    isAscending: boolean;
    orderByColumn: string;
    searchText: string;
}

class SuppliersView extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrList>();

    constructor(props) {
        super(props);

        const query = new URLSearchParams(props.location.search);
        const categoryId = query.get("categoryId");
        const tagId = query.get("tagId");

        this.state = {
            isLoading: true,
            suppliers: [],
            categoryId,
            tagId,
            displayDeleted: false,
            isAscending: false,
            orderByColumn: "",
            searchText: "",
        };
    }

    componentDidMount() {
        let req;

        if (this.state.categoryId) {
            req = api.get("/api/suppliers/categories/" + this.state.categoryId);
        } else if (this.state.tagId) {
            req = api.get("/api/suppliers/tags/" + this.state.tagId);
        }

        if (req) {
            req.then((response) => {
                this.setState({
                    category: this.state.categoryId ? response.data : null,
                    tag: this.state.tagId ? response.data : null,
                });
            });
        }

        updateSidebar(this.props.isAdmin, this.props.isEditor, this.props.appName, this.props.dispatch);
    }

    private fetchList = (skip, take, columnId, isAscending, searchQuery) => {
        return new Promise((resolve, reject) => {
            this.setState({
                isAscending,
                orderByColumn: columnId,
                searchText: searchQuery,
            });

            api.get(`/api/v1/suppliers`, {
                params: {
                    includeDeleted: this.state.displayDeleted,
                    isAscending,
                    orderByColumn: columnId,
                    searchText: searchQuery,
                    skip,
                    take,
                    tagId: this.state.tagId,
                    categoryId: this.state.categoryId,
                },
            }).then((response: AxiosResponse) => {
                resolve({
                    data: response.data.items,
                    totalCount: response.data.totalCount,
                });
            });
        });
    };

    render() {
        return (
            <div className="SuppliersView">
                <div className="breadcrumbs-wrapper">
                    <SuppliersBreadcrumbs />
                </div>
                <Helmet>
                    <title>{!!this.props.appName ? localize(this.props.appName) : localize("Foretagskatalogen")}</title>
                </Helmet>
                <PageHeader
                    title={!!this.props.appName ? localize(this.props.appName) : localize("Foretagskatalogen")}
                    displaySearch
                    onSearchQueryChange={(value: string) => {
                        this.listRef.current.setSearchText(value);
                    }}
                    buttons={[
                        ...(
                            this.props.isAdmin || this.props.isEditor
                                ? [{
                                    text: localize("Alternativ"),
                                    subMenuProps: {
                                        items: [
                                            {
                                                key: "settings",
                                                text: localize("Installningar"),
                                                onClick: () => {
                                                    this.props.history.push({
                                                        pathname: `/suppliers/settings`,
                                                    });
                                                },
                                            },
                                            {
                                                key: "includeDeleted",
                                                text: this.state.displayDeleted
                                                    ? localize("DoljBorttagna")
                                                    : localize("VisaBorttagna"),
                                                onClick: () => {
                                                    this.setState(
                                                        {
                                                            displayDeleted: !this.state.displayDeleted,
                                                        },
                                                        () => {
                                                            this.listRef.current.fetch();
                                                        }
                                                    );
                                                },
                                            },
                                            {
                                                key: "export",
                                                text: localize("ExporteraUtsokningTillCSV"),
                                                onClick: () => {
                                                    window.open(
                                                        "/api/suppliers/csv?" +
                                                        new URLSearchParams({
                                                            includeDeleted: this.state.displayDeleted.toString(),
                                                            orderByColumn: this.state.orderByColumn,
                                                            isAscending: this.state.isAscending.toString(),
                                                            searchText: this.state.searchText,
                                                            tagId: this.state.tagId || 0,
                                                            categoryId: this.state.categoryId || 0,
                                                        })
                                                    );
                                                },
                                            },
                                        ],
                                    }
                                }]
                                : []),
                        ...(!this.props.isAdmin && !this.props.isEditor
                            ? []
                            : [
                                {
                                    key: "add",
                                    text: localize("SkapaNy"),
                                    onClick: () => {
                                        this.props.history.push({
                                            pathname: `/suppliers/create`,
                                        });
                                    },
                                    iconProps: { iconName: "Add" },
                                    className: "commandBarAddButton",
                                    theme: "primary"
                                },
                            ])
                    ]}
                />
                {(!!this.state.tag || !!this.state.category) && (
                    <div className="suppliersFilter">
                        <Label>
                            {localize(this.state.tag ? "VisarRaderMedProduktkoden" : "VisarRaderIKategorin") +
                                ": " +
                                (this.state.tag ? this.state.tag.name : this.state.category.name)}
                        </Label>
                        <a
                            className={"linkFGColor"}
                            onClick={() => {
                                this.setState(
                                    {
                                        tagId: null,
                                        categoryId: null,
                                        tag: null,
                                        category: null,
                                    },
                                    () => {
                                        this.listRef.current.fetch();
                                    }
                                );
                            }}
                        >
                            {localize("RensaDetta")}
                        </a>
                    </div>
                )}
                <SpintrList
                    ref={this.listRef}
                    fetch={this.fetchList}
                    orderByColumn={"Name"}
                    columns={[
                        {
                            name: localize("Namn"),
                            fieldName: "Name",
                            minWidth: 200,
                            onRender: (item) => {
                                return (
                                    <Link
                                        to={`/suppliers/${item.id}`}
                                        style={{
                                            ...(item.isDeleted ? { textDecorationLine: "line-through" } : {}),
                                        }}
                                    >
                                        {item.name}
                                    </Link>
                                );
                            },
                        },
                        {
                            name: localize("Ort"),
                            fieldName: "city",
                        },
                        {
                            name: localize("Leverantorsnummer_small"),
                            fieldName: "externalId",
                        },
                        {
                            name: localize("Kategori"),
                            fieldName: "categoryName",
                        },
                        {
                            name: localize("SUPPLIER_GROUPS"),
                            fieldName: "Groups",
                            minWidth: 150,
                            onRender: (item: any) => {
                                let s = !!item.groups && item.groups.length > 0 ?
                                    item.groups.map((g) => {
                                        return g.name
                                    }).join(", ") :
                                    "";

                                return (
                                    <span title={s}>{s}</span>
                                );
                            },
                        },
                        {
                            name: localize("Kontaktpersoner"),
                            fieldName: "User.Name",
                            minWidth: 200,
                            onRender: (item: any) => {
                                const users = item.users;

                                if (!users) {
                                    return null;
                                }

                                if (users.length === 1) {
                                    const u = item.users[0];

                                    return (
                                        <SpintrUser
                                            id={u.id}
                                            imageUrl={u.image}
                                            name={u.name}
                                            subText={u.info}
                                            personalName={true}
                                        />
                                    );
                                }

                                return (
                                    <div className="SupplierUsers">
                                        {users.map((u) => {
                                            return (
                                                <div className="SupplierUser" key={u.id}>
                                                    <SpintrUser
                                                        size={26}
                                                        id={u.id}
                                                        imageUrl={u.image}
                                                        name={u.name}
                                                        hideText={true}
                                                        personalName={true}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            },
                        },

                        {
                            name: "",
                            key: "actionmenu",
                            minWidth: 40,
                            onRender: (item: any) => {
                                if (!(this.props.isAdmin || this.props.isEditor)) {
                                    return null;
                                }

                                return (
                                    <ActionMenu
                                        categories={[
                                            {
                                                items: [
                                                    {
                                                        text: localize("Redigera"),
                                                        onClick: () => {
                                                            this.props.history.push({
                                                                pathname: `/suppliers/${item.id}/edit`,
                                                            });
                                                        },
                                                    },
                                                    {
                                                        text: item.isDeleted
                                                            ? localize("Aterstall")
                                                            : localize("TaBort"),
                                                        onClick: () => {
                                                            const onConfirm = () => {
                                                                api.delete(`/api/suppliers/${item.id}`).then(() => {
                                                                    this.listRef.current.fetch();
                                                                });
                                                            };

                                                            const title = item.isDeleted
                                                                ? localize("Aterstallinnehall")
                                                                : localize("RaderaInnehall");

                                                            const message = item.isDeleted
                                                                ? localize("ArDuSakerPaAttDuVillGoraDetta")
                                                                : localize("ArDuSakerPaAttDuVillX").replace(
                                                                    "{{X}}",
                                                                    localize("RaderaDetta").toLowerCase()
                                                                );

                                                            this.props.dispatch(
                                                                setConfirmPopup({
                                                                    isOpen: true,
                                                                    title,
                                                                    message,
                                                                    onConfirm,
                                                                })
                                                            );
                                                        },
                                                    },
                                                ],
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ]}
                    disableCommandBar
                    disableSearch
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,
    instance: state.instance,
    currentUser: state.profile.active,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    appName: state.instance.get("suppliersAppName"),
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(SuppliersView));
