import { AxiosResponse } from 'axios';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { localize } from 'src/l10n';
import { setConfirmPopup } from 'src/popups/actions';
import api from 'src/spintr/SpintrApi';
import { ActionMenu, ContentWithSubmenu, PageHeader } from 'src/ui';
import SpintrList from 'src/ui/components/SpintrList/SpintrList';
import SuppliersBreadcrumbs from './SuppliersBreadcrumbs';
import { updateSidebar } from './utils';

interface IProps {
    instance?: any;
    currentUser?: any;
    history?: any;
    isAdmin?: boolean;
    isEditor?: boolean;
    dispatch?: any;
    appName?: string;
}

interface IState {
    isLoading: boolean;
    suppliers: any[];
    displayDeleted: boolean;
}

class SuppliersFileCategoriesView extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrList>();

    state = {
        isLoading: true,
        suppliers: [],
        displayDeleted: false
    };

    componentDidMount() {
        updateSidebar(this.props.isAdmin, this.props.isEditor, this.props.appName, this.props.dispatch);
    }

    render() {
        return (
            <div className="SuppliersView">
                <div className="breadcrumbs-wrapper">
                    <SuppliersBreadcrumbs />
                </div>
                <Helmet>
                    <title>{localize("Filkategorier")}</title>
                </Helmet>
                <PageHeader
                    title={localize("Filkategorier")}
                    displaySearch
                    onSearchQueryChange={(value: string) => {
                        this.listRef.current.setSearchText(value);
                    }}
                    buttons={[
                        ...(this.props.isAdmin ? [{
                            subMenuProps: {
                                items: [
                                    {
                                        key: "includeDeleted",
                                        text: this.state.displayDeleted ?
                                            localize("DoljBorttagna") :
                                            localize("VisaBorttagna"),
                                        onClick: () => {
                                            this.setState({
                                                displayDeleted: !this.state.displayDeleted
                                            }, () => {
                                                this.listRef.current.fetch();
                                            });
                                        },
                                    }
                                ],
                            }
                        }] : []),
                        ...(!this.props.isAdmin && !this.props.isEditor ? [] : [
                            {
                                key: "add",
                                text: localize("SkapaNy"),
                                onClick: () => {
                                    this.props.history.push({
                                        pathname: `/suppliers/filecategories/create`,
                                    });
                                },
                                iconProps: { iconName: "Add" },
                                className: "commandBarAddButton",
                                theme: "primary"
                            },
                        ])
                    ]}
                />
                <SpintrList
                    ref={this.listRef}
                    fetch={(skip, take, columnId, isAscending, searchQuery) => {
                        return new Promise((resolve, reject) => {
                            api
                                .get(`/api/v1/suppliers/filecategories`, {
                                    params: {
                                        includeDeleted: this.state.displayDeleted,
                                        isAscending,
                                        orderByColumn: columnId,
                                        searchText: searchQuery,
                                        skip,
                                        take,
                                    },
                                })
                                .then((response: AxiosResponse) => {
                                    resolve({ data: response.data.items, totalCount: response.data.totalCount });
                                });
                        });
                    }}
                    orderByColumn={"Name"}
                    columns={[
                        {
                            name: localize("Namn"),
                            fieldName: "name",
                            minWidth: 250,
                            onRender: (item) => {
                                return (
                                    <span
                                        style={{
                                            ...(item.isDeleted ? { textDecorationLine: "line-through" } : {}),
                                        }}
                                    >
                                        {item.name}
                                    </span>
                                );
                            },
                        },
                        {
                            name: "",
                            key: "actionmenu",
                            minWidth: 40,
                            onRender: (item: any) => {
                                if (!this.props.isAdmin && !this.props.isEditor) {
                                    return null;
                                }

                                return (
                                    <ActionMenu
                                        categories={[
                                            {
                                                items: [
                                                    {
                                                        text: localize("Redigera"),
                                                        onClick: () => {
                                                            this.props.history.push({
                                                                pathname: `/suppliers/filecategories/${item.id}/edit`,
                                                            });
                                                        },
                                                    },
                                                    {
                                                        text: item.isDeleted
                                                            ? localize("Aterstall")
                                                            : localize("TaBort"),
                                                        onClick: () => {
                                                            const onConfirm = () => {
                                                                api.delete(`/api/suppliers/filecategories/${item.id}`).then(() => {
                                                                    this.listRef.current.fetch();
                                                                });
                                                            }

                                                            const title = item.isDeleted ?
                                                                localize("Aterstallinnehall") :
                                                                localize("RaderaInnehall");

                                                            const message = item.isDeleted ?
                                                                localize("ArDuSakerPaAttDuVillGoraDetta") :
                                                                localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("RaderaDetta").toLowerCase());

                                                            this.props.dispatch(setConfirmPopup({
                                                                isOpen: true,
                                                                title,
                                                                message,
                                                                onConfirm
                                                            }));
                                                        },
                                                    },
                                                ],
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ]}
                    disableCommandBar
                    disableSearch
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,
    instance: state.instance,
    currentUser: state.profile.active,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    appName: state.instance.get("suppliersAppName")
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(SuppliersFileCategoriesView));
