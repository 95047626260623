import React, { CSSProperties, ReactElement, useMemo } from "react";
import { TimelineCellOverlayProps } from "./TimelineCellOverlay.types";

function TimelineCellOverlay(props: TimelineCellOverlayProps): ReactElement {
    const { columnWidth, index, intervalCount, isToday, timelineMode, timelineWidth } = props;

    const style = useMemo<CSSProperties>(() => {
        return {
            backgroundColor: isToday ? "rgba(0, 0, 0, 0.04)" : undefined,
            left: `${((columnWidth * index) / timelineWidth) * 100}%`,
            right: `${((columnWidth * (intervalCount - index - 1)) / timelineWidth) * 100}%`,
        };
    }, [index, intervalCount, timelineMode, timelineWidth, isToday])

    return (
        <div className="TimelineCellOverlay" style={style} />
    );
}

export default TimelineCellOverlay;
