import { AxiosResponse } from "axios";
import { Checkbox, CommandBar, SelectionMode, ShimmeredDetailsList } from "@fluentui/react";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/index";
import { ActionMenu, Breadcrumbs, ContentWithSubmenu, Label, Loader, PageHeader, Submenu } from "src/ui";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import "./TodoView.scss";
import api from "src/spintr/SpintrApi";
import { expandToActiveItem, setSidebarItems, setSidebarMode } from "src/sidebar/actions";
import { IVisageSidebarMenuItem, VisageSidebarMenuItemActiveMode, VisageSidebarMode } from "src/sidebar";

interface Props extends RouteComponentProps {
    enableDepartmentLists: boolean;
    history: any;
    match: any;
    dispatch?: any;
}

interface State {
    isLoading: boolean;
    lists: any;
    overview: any;
    list: any;
    listTodos: any[];
    checkState: any[];
    active: number;
    total: number;
    showCompleted: boolean;
    showDeleted: boolean;
}

class TodoView extends Component<Props, State> {
    private overviewListColumns = [];
    private listRef = React.createRef<SpintrList>();
    private _isMounted: boolean = false;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            lists: {} as any,
            overview: {} as any,
            list: {} as any,
            listTodos: [],
            checkState: [],
            active: 0,
            total: 0,
            showCompleted: false,
            showDeleted: false,
        };

        this.overviewListColumns = [
            {
                key: "checkbox",
                name: "",
                minWidth: 20,
                maxWidth: 20,
                onRender: (item) => {
                    return (
                        <Checkbox
                            defaultChecked={item.isComplete}
                            onChange={() => {
                                let alreadyChecked = this.state.checkState.findIndex((i) => i.id === item.id);
                                let isComplete = false;
                                if (alreadyChecked) {
                                    isComplete = !this.state.checkState[alreadyChecked];
                                    let checkState = this.state.checkState;
                                    checkState[alreadyChecked] = !checkState[alreadyChecked];
                                    this.setState({ checkState });
                                }
                                api.post(`/api/v1/todos/togglecompleted/${item.id}`, {
                                    id: item.id,
                                    isComplete,
                                });
                            }}
                        />
                    );
                },
            },
            {
                key: "c0",
                name: localize("Namn"),
                fieldName: "name",
                minWidth: 50,
                onRender: (item) => {
                    return (
                        <span>
                            <Link to={`/todo/list/${item.list.id}/${item.id}`}>{item.name}</Link>
                        </span>
                    );
                },
            },
            {
                key: "c1",
                name: localize("Lista"),
                fieldName: "listName",
                minWidth: 150,
                onRender: (item) => {
                    return (
                        <span>
                            <Link to={`/todo/list/${item.list.id}`}>{item.listName}</Link>
                        </span>
                    );
                },
            },
            {
                key: "actionmenu",
                minWidth: 40,
                onRender: (item: any) => {
                    return (
                        <ActionMenu
                            categories={[
                                {
                                    items: [
                                        {
                                            text: localize("Redigera"),
                                            onClick: () => {
                                                this.props.history.push({
                                                    pathname: `/todo/list/${item.list.id}/${item.id}/edit`,
                                                });
                                            },
                                        },
                                        {
                                            text: item.isDeleted ? localize("Aterstall") : localize("TaBort"),
                                            onClick: () => {
                                                api
                                                    .post(`/api/v1/todos/toggledeleted/${item.id}`)
                                                    .then((_response) => {
                                                        this.overviewFetch();
                                                    });
                                            },
                                        },
                                    ],
                                },
                            ]}
                        />
                    );
                },
            },
        ];
    }

    public componentDidUpdate = (prevProps) => {
        if (
            this.props.match.params["listId"] !== prevProps.match.params["listId"] &&
            this.props.match.params["listId"]
        ) {
            this.listRef.current.fetch();
            this.listFetch();
        }
    };

    listFetch = () => {
        const { listId } = this.props.match.params;

        this.setState({ isLoading: true }, () => {
            Promise.all([
                api.get(`/api/v1/todos/count?listId=${listId}`).then((response) => {
                    this.setState({ active: response.data.active, total: response.data.total });
                }),
                api.get("/api/v1/todos/lists").then((response) => {
                    this.setState({ lists: response.data });
                }),
                api.get(`/api/v1/todos/list?listId=${listId}`).then((response) => {
                    this.setState({ list: response.data });
                }),
            ])
                .then(() => {
                    this.updateSideBar();
                    this.setState({ isLoading: false });
                })
                .catch(() => { });
        });
    };

    overviewFetch = () => {
        this.setState({ isLoading: true }, () => {
            Promise.all([
                api.get(`/api/v1/todos/count?listId=0`).then((response) => {
                    this.setState({ active: response.data.active, total: response.data.total });
                }),
                api.get("/api/v1/todos/lists").then((response) => {
                    this.setState({ lists: response.data });
                }),
                api.get("/api/v1/todos/overview").then((response) => {
                    this.setState({ overview: response.data });
                }),
            ]).then(() => {
                this.updateSideBar();
                this.setState({ isLoading: false });
            });
        });
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount = () => {
        this._isMounted = true;

        const { listId } = this.props.match.params;

        if (listId) {
            this.listFetch();
        } else {
            this.overviewFetch();
        }
    };

    updateSideBar() {
        if (!this._isMounted) {
            return;
        }

        let children: IVisageSidebarMenuItem[] = [{
            key: "todos-overview",
            name: localize("Overblick"),
            route: "/todo",
            routes: ["/todo"],
        }];

        if (!!this.state.lists.mylists &&
            this.state.lists.mylists.length > 0) {
            children.push({
                key: "todos-my-lists",
                name: localize("MinaListor"),
                children: this.state.lists.mylists.map((list: any) => {
                    return {
                        key: "todos-my-lists-" + list.id,
                        name: list.name,
                        route: "/todo/list/" + list.id,
                        routes: ["/todo/list/" + list.id]
                    }
                })
            });
        }

        if (!!this.state.lists.projects &&
            this.state.lists.projects.length > 0) {
            children.push({
                key: "todos-projects",
                name: localize("Samarbeten"),
                children: this.state.lists.projects.map((list: any) => {
                    return {
                        key: "todos-projects-" + list.id,
                        name: list.name,
                        route: "/todo/list/" + list.id,
                        routes: ["/todo/list/" + list.id]
                    }
                })
            });
        }

        if (!!this.state.lists.other &&
            this.state.lists.other.length > 0) {
            children.push({
                key: "todos-other",
                name: localize("GemensammaListor"),
                children: this.state.lists.other.map((list: any) => {
                    return {
                        key: "todos-other-" + list.id,
                        name: list.name,
                        route: "/todo/list/" + list.id,
                        routes: ["/todo/list/" + list.id]
                    }
                })
            });
        }

        let items: IVisageSidebarMenuItem[] = [{
            key: "todos",
            name: localize("appTodos"),
            icon: "Tick Square",
            activeIcon: "Tick Square",
            //route: "/todo",
            //routes: ["/todo"],
            search: "",
            children,
            isExpanded: true,
            isLoading: false,
            moduleKey: "todo",
            //activeMode: VisageSidebarMenuItemActiveMode.relative
        }];

        this.props.dispatch(setSidebarItems(items, "todos"));
        this.props.dispatch(setSidebarMode(VisageSidebarMode.submenu));
        this.props.dispatch(expandToActiveItem());
    }

    renderOverview = () => {
        const { overview, active, total } = this.state;

        return (
            <div className="toDoContent">
                <PageHeader
                    title={localize("Overblick")}
                    subText={active + " " + localize("Aktiva") + " - " + total + " " + localize("Totalt")}
                    buttons={[
                        {
                            key: "add",
                            text: localize("SkapaNyLista"),
                            onClick: () => {
                                this.props.history.push({
                                    pathname: `/todo/create`,
                                });
                            },
                            iconProps: { iconName: "Add" },
                            className: "commandBarAddButton",
                            theme: "primary"
                        },
                    ]} />
                <Label as="h4" size="h4">
                    {localize("MinaListor")}
                </Label>
                <Label size="small-2">
                    {overview.myTodosTotalCount}{" "}
                    {overview.myTodosTotalCount === 1 ? localize("Ubertype29_0_4") : localize("Ubertype29_1_0")}
                    {overview.myTodosTotalCount > overview.mytodos.length && (
                        <>
                            {" - "} <Link to={`/todo/type/1`}>{localize("VisaAlla")}</Link>
                        </>
                    )}
                </Label>
                <div className="listGroup">
                    <ShimmeredDetailsList
                        enableShimmer={this.state.isLoading}
                        selectionMode={SelectionMode.none}
                        columns={this.overviewListColumns}
                        items={overview.mytodos}
                    />
                </div>

                <Label as="h4" size="h4">
                    {localize("Grupplistor")}
                </Label>
                <Label size="small-2">
                    {overview.groupTodosTotalCount}{" "}
                    {overview.groupTodosTotalCount === 1 ? localize("Ubertype29_0_4") : localize("Ubertype29_1_0")}
                    {overview.groupTodosTotalCount > overview.grouptodos.length && (
                        <>
                            {" - "} <Link to={`/todo/type/5`}>{localize("VisaAlla")}</Link>
                        </>
                    )}
                </Label>
                <div className="listGroup">
                    <ShimmeredDetailsList
                        enableShimmer={this.state.isLoading}
                        selectionMode={SelectionMode.none}
                        columns={this.overviewListColumns}
                        items={overview.grouptodos}
                    />
                </div>

                <Label as="h4" size="h4">
                    {localize("GemensammaListor")}
                </Label>
                <Label size="small-2">
                    {overview.sharedTodosTotalCount}{" "}
                    {overview.sharedTodosTotalCount === 1 ? localize("Ubertype29_0_4") : localize("Ubertype29_1_0")}
                    {overview.sharedTodosTotalCount > overview.sharedtodos.length && (
                        <>
                            {" - "} <Link to={`/todo/type/2`}>{localize("VisaAlla")}</Link>
                        </>
                    )}
                </Label>
                <div className="listGroup">
                    <ShimmeredDetailsList
                        enableShimmer={this.state.isLoading}
                        selectionMode={SelectionMode.none}
                        columns={this.overviewListColumns}
                        items={overview.sharedtodos}
                    />
                </div>
                {this.props.enableDepartmentLists && (
                    <>
                        <Label as="h4" size="h4">
                            {localize("Avdelningslistor")}
                        </Label>
                        <Label size="small-2">
                            {overview.departmentTodosTotalCount}{" "}
                            {overview.departmentTodosTotalCount === 1
                                ? localize("Ubertype29_0_4")
                                : localize("Ubertype29_1_0")}
                            {overview.departmentTodosTotalCount > overview.departmenttodos.length && (
                                <>
                                    {" - "} <Link to={`/todo/type/4`}>{localize("VisaAlla")}</Link>
                                </>
                            )}
                        </Label>
                        <div className="listGroup">
                            <ShimmeredDetailsList
                                enableShimmer={this.state.isLoading}
                                selectionMode={SelectionMode.none}
                                columns={this.overviewListColumns}
                                items={overview.departmenttodos}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    };

    formatDate(dateString) {
        const d = new Date(dateString);
        return d.toLocaleDateString();
    }

    renderList = () => {
        const { list, listTodos, active, total, showDeleted, showCompleted } = this.state;
        const { listId } = this.props.match.params;

        return (
            <>
                <PageHeader title={list.name} />
                <div id="top-right-actionmenu" style={{ position: "absolute", top: "53px", right: "47px" }}>
                    <ActionMenu
                        categories={[
                            {
                                items: [
                                    {
                                        text: localize("NyAttGoraPunkt"),
                                        onClick: () => {
                                            this.props.history.push({
                                                pathname: `/todo/list/${listId}/create`,
                                            });
                                        },
                                    },
                                    {
                                        text: showCompleted ? localize("DoljAvklarade") : localize("VisaAvklarade"),
                                        onClick: () => {
                                            this.setState({ showCompleted: !showCompleted }, () => {
                                                this.listRef.current.fetch();
                                            });
                                        },
                                    },
                                    {
                                        text: showDeleted ? localize("DoljBorttagna") : localize("VisaBorttagna"),
                                        onClick: () => {
                                            this.setState({ showDeleted: !showDeleted }, () => {
                                                this.listRef.current.fetch();
                                            });
                                        },
                                    },
                                    {
                                        text: localize("Redigera"),
                                        onClick: () => {
                                            this.props.history.push({
                                                pathname: `/todo/list/${listId}/edit`,
                                            });
                                        },
                                    },
                                ],
                            },
                        ]}
                    />
                </div>
                <div className="toDoContent">
                    <Label className="active" as="h4" size="body-4">
                        {active} {localize("Aktiva")} - {total} {localize("Totalt")}
                    </Label>

                    <SpintrList
                        ref={this.listRef}
                        fetch={(skip, take, columnId, isAscending, searchQuery) => {
                            return new Promise((resolve, reject) => {
                                api
                                    .get(`/api/v1/todos`, {
                                        params: {
                                            listId,
                                            bypassDirect: true,
                                            showDeleted: this.state.showDeleted,
                                            showCompleted: this.state.showCompleted,
                                            isAscending: isAscending,
                                            orderByColumn: columnId,
                                            searchText: searchQuery,
                                            take: take,
                                            skip: skip,
                                        },
                                    })
                                    .then((response: AxiosResponse) => {
                                        this.setState({ listTodos: response.data.items });
                                        resolve({ data: response.data.items, totalCount: response.data.totalCount });
                                    });
                            });
                        }}
                        buttons={[
                            {
                                key: "add",
                                text: localize("LaggTillNyAttGoraPunkt"),
                                onClick: () => {
                                    this.props.history.push({
                                        pathname: `/todo/list/${listId}/create`,
                                    });
                                },
                                iconProps: { iconName: "Add" },
                                className: "commandBarAddButton",
                            },
                        ]}
                        orderByColumn={"dueDate"}
                        columns={[
                            {
                                key: "checkbox",
                                name: "",
                                minWidth: 20,
                                maxWidth: 20,
                                onRender: (item) => {
                                    let listItem = listTodos.findIndex((f) => f.id === item.id);
                                    return (
                                        <Checkbox
                                            checked={listTodos[listItem] ? listTodos[listItem].isComplete : false}
                                            onChange={() => {
                                                api.post(`/api/v1/todos/togglecompleted/${item.id}`, {
                                                    id: item.id,
                                                    isComplete: !item.isComplete,
                                                });
                                                listTodos[listItem].isComplete = !listTodos[listItem].isComplete;
                                                this.setState({ listTodos });
                                            }}
                                        />
                                    );
                                },
                            },
                            {
                                key: "c0",
                                name: localize("Namn"),
                                fieldName: "name",
                                minWidth: 250,
                                onRender: (item) => {
                                    if (item.isDeleted)
                                        return (
                                            <span>
                                                <s>
                                                    <Link className="" to={`/todo/list/${item.list.id}/${item.id}`}>
                                                        {item.name}
                                                    </Link>
                                                </s>
                                            </span>
                                        );
                                    else
                                        return (
                                            <span>
                                                <Link className="" to={`/todo/list/${item.list.id}/${item.id}`}>
                                                    {item.name}
                                                </Link>
                                            </span>
                                        );
                                },
                            },
                            {
                                key: "c1",
                                name: localize("Datum"),
                                fieldName: "dueDate",
                                minWidth: 100,
                                onRender: (item) => {
                                    return <span>{item.dueDate && this.formatDate(item.dueDate)}</span>;
                                },
                            },
                            {
                                name: "",
                                key: "actionmenu",
                                minWidth: 40,
                                onRender: (item: any) => {
                                    return (
                                        <ActionMenu
                                            categories={[
                                                {
                                                    items: [
                                                        {
                                                            text: localize("Redigera"),
                                                            onClick: () => {
                                                                this.props.history.push({
                                                                    pathname: `/todo/list/${listId}/${item.id}/edit`,
                                                                });
                                                            },
                                                        },
                                                        {
                                                            text: item.isDeleted
                                                                ? localize("Aterstall")
                                                                : localize("TaBort"),
                                                            onClick: () => {
                                                                api
                                                                    .post(`/api/v1/todos/toggledeleted/${item.id}`)
                                                                    .then((_response) => {
                                                                        this.listRef.current.fetch();
                                                                    });
                                                            },
                                                        },
                                                    ],
                                                },
                                            ]}
                                        />
                                    );
                                },
                            },
                        ]}
                    />
                </div>
            </>
        );
    };

    render() {
        if (this.state.isLoading) return <Loader />;
        const { listId } = this.props.match.params;
        return (
            <div className="TodoView">
                <Helmet>
                    <title>{localize("AttGora")}</title>
                </Helmet>
                <div>
                    <Breadcrumbs
                        displayInstance
                        items={[
                            {
                                text: localize("AttGora"),
                                key: 0,
                                link: "/todo",
                            },
                        ]}
                    />
                    {listId ? this.renderList() : this.renderOverview()}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: IApplicationState, props: Props): Props => ({
    ...props,
    enableDepartmentLists: state.instance.get("enableTodoDepartmentLists"),
});

const ConnectedTodoView = connect(mapStateToProps)(TodoView);

// @ts-ignore
export default withRouter(ConnectedTodoView);
