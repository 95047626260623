import React, { Fragment, ReactElement } from "react";
import { ConditionalRenderProps } from "./ConditionalRender.types";

function ConditionalRender({ children, condition }: ConditionalRenderProps): ReactElement {
    if (!condition) {
        return null;
    }

    return <Fragment>{children()}</Fragment>;
}

export default ConditionalRender;
